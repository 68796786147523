//#region IMPORT

import { Router } from "@angular/router";
import { PartialObserver } from "rxjs";
import { BRIModel } from "src/app/models/bri.model";
import { UserModel } from "src/app/models/user.model";
import { SessionService } from "src/app/services/session.service";
import { BasePublicComponent } from "./basepublic.component";

//#endregion


//#region CLASS

export class BaseAuthourizeComponent extends BasePublicComponent
{
	//#region DECLARATION

	public _modelUserSignIn: UserModel;
	public _modelBRISignIn: BRIModel;

	//#endregion


	//#region CONSTRUCTOR

	constructor(serviceSession: SessionService, router: Router)
	{
		super(serviceSession, router);
		this._modelUserSignIn = new UserModel();
		this._modelBRISignIn = new BRIModel();

		const observerPartialModelUserSignIn: PartialObserver<UserModel> =
		{
			next: (modelUser: UserModel) => { this._modelUserSignIn = modelUser; },
			error: () => { },
			complete: () => { }
		};

		const observerPartialModelBRISignIn: PartialObserver<BRIModel> =
		{
			next: (modelBRI: BRIModel) => { this._modelBRISignIn = modelBRI; },
			error: () => { },
			complete: () => { }
		};

		this._serviceSession._modelUserSignIn.subscribe(observerPartialModelUserSignIn);
		this._serviceSession._modelBRISignIn.subscribe(observerPartialModelBRISignIn);
	}

	//#endregion


	//#region FUNCTION

	protected signOut(): void
	{
		this._serviceSession.clearKey();
		this._serviceSession.clearSession();
		this._serviceSession.clearSessionQuery();

		this._router.navigate(["signin"]);
	}

	//#endregion


	//#region CHECK

	protected checkURL(stringURLPath: string): boolean
	{
		return this._router.url.indexOf(stringURLPath) > -1;
	}

	//#endregion
}

//#endregion