//#region IMPORT

import { Color } from "@swimlane/ngx-charts";
import { DashboardBaseModel } from "./bases/dashboard.base.model";
import { ChartItemModel } from "./chartitem.model";

//#endregion

//#region CLASS

export class DashboardProductHomeOwnershipCreditSummaryModel extends DashboardBaseModel
{
	listModelChartItemStatusType?: Array<ChartItemModel> = [];
	modelColorStatusType: Color = this.setColorDefault();
	listModelChartItemStatusOnProgress?: Array<ChartItemModel> = [];
	modelColorStatusOnProgress: Color = this.setColorDefault();
	listModelChartItemStatusApproved?: Array<ChartItemModel> = [];
	modelColorStatusApproved: Color = this.setColorDefault();
	listModelChartItemStatusRejected?: Array<ChartItemModel> = [];
	modelColorStatusRejected: Color = this.setColorDefault();
	listModelChartItemBuildingPrice?: Array<ChartItemModel> = [];
	modelColorBuildingPrice: Color = this.setColorDefault();

	constructor()
	{
		super();
	}

	//#region CLEAR

	clearChartResult(): void
	{
		this.listModelChartItemStatusType = [];
		this.modelColorStatusType = this.setColorDefault();
		this.listModelChartItemStatusOnProgress = [];
		this.modelColorStatusOnProgress = this.setColorDefault();
		this.listModelChartItemStatusApproved = [];
		this.modelColorStatusApproved = this.setColorDefault();
		this.listModelChartItemStatusRejected = [];
		this.modelColorStatusRejected = this.setColorDefault();
		this.listModelChartItemBuildingPrice = [];
		this.modelColorBuildingPrice = this.setColorDefault();
	}

	clearChartForRequest(): void
	{
		this.listModelChartItemStatusType = undefined;
		this.modelColorStatusType = this.setColorDefault();
		this.listModelChartItemStatusOnProgress = undefined;
		this.modelColorStatusOnProgress = this.setColorDefault();
		this.listModelChartItemStatusApproved = undefined;
		this.modelColorStatusApproved = this.setColorDefault();
		this.listModelChartItemStatusRejected = undefined;
		this.modelColorStatusRejected = this.setColorDefault();
		this.listModelChartItemBuildingPrice = undefined;
		this.modelColorBuildingPrice = this.setColorDefault();
	}

	//#endregion
}

//#endregion