//#region IMPORT

import { GeneralModel } from "./bases/general.model";
import { CityModel } from "./city.model";

//#endregion


//#region CLASS

export class PostalModel extends GeneralModel
{
	/*
		Attribute - START
		Description : Attribute for postal model, used for link to postal code.
		Author: Ibrahim Aziz.
		Created on : Tuesday, 30 March 2021. 				Updated on : Tuesday, 30 March 2021.
		Created by : Ibrahim Aziz.							Updated by : Ibrahim Aziz.
		Version : 1.0:2.
	*/

	ID?: number;
	Code?: string;
	Name?: string;
	CityID?: number;
	FLDID?: string;

	modelCity?: CityModel;

	/* Attribute - END */
}

//#endregion