<div id="divBodyDashboard" class="DivBodyGeneral">
    <header class="HeaderGeneral">
		<h2>Dasbor</h2>
		<p>Aplikasi persetujuan otomatis BRI KPR</p>
    </header>

	<div id="divMainDashboard" class="DivContainerVertical">
        <div class="DivContainer">
			<form>
				<div class="DivForm">
					<h3>Saring berdasarkan</h3>

					<fieldset>
						<div class="DivFormHorizontalContainer">
							<div class="DivForm2Column">
								<label for="datePeriod">Periode Upload</label>
								<mat-form-field appearance="fill">
									<mat-label>Jarak tanggal</mat-label>
									<mat-date-range-input [rangePicker]="picker">
										<input matStartDate placeholder="Tanggal awal" [value]="_modelDashboardBase.StartDate" (dateChange)="getStartDate($event)">
										<input matEndDate placeholder="Tanggal akhir" [value]="_modelDashboardBase.EndDate" (dateChange)="getEndDate($event)">
									</mat-date-range-input>
									<mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
									<mat-date-range-picker #picker></mat-date-range-picker>
								</mat-form-field>
							</div>
						</div>
					</fieldset>
				</div>
			</form>

			<div id="divFormStatus" class="DivForm">
				<h3>Status Data KPR</h3>

				<div class="DivFormVerticalContainer">
					<ngx-charts-bar-vertical class="ChartBar"
						[view]="_arraySizeChartBarLarge"
						[scheme]="_modelDashboardProductHomeOwnershipCreditSummary.modelColorStatusOnProgress"
						[results]="_modelDashboardProductHomeOwnershipCreditSummary.listModelChartItemStatusOnProgress"
						[gradient]="false"
						[xAxis]="_booleanLegendShow"
						[yAxis]="_booleanLegendShow"
						[legend]="(_modelDashboardProductHomeOwnershipCreditSummary.listModelChartItemStatusOnProgress?.length ?? 0) < 10"
						[legendTitle]="'Belum Disetujui'"
						[legendPosition]="_legendPositionBar"
						[showXAxisLabel]="_booleanLabelShow"
						[showYAxisLabel]="_booleanLabelShow"
						[xAxisLabel]="'true'"
						[yAxisLabel]="'true'">
					</ngx-charts-bar-vertical>

					<ngx-charts-bar-vertical class="ChartBar"
						[view]="_arraySizeChartBarLarge"
						[scheme]="_modelDashboardProductHomeOwnershipCreditSummary.modelColorStatusApproved"
						[results]="_modelDashboardProductHomeOwnershipCreditSummary.listModelChartItemStatusApproved"
						[gradient]="false"
						[xAxis]="_booleanLegendShow"
						[yAxis]="_booleanLegendShow"
						[legend]="(_modelDashboardProductHomeOwnershipCreditSummary.listModelChartItemStatusApproved?.length ?? 0) < 10"
						[legendTitle]="'Disetujui'"
						[legendPosition]="_legendPositionBar"
						[showXAxisLabel]="_booleanLabelShow"
						[showYAxisLabel]="_booleanLabelShow"
						[xAxisLabel]="'true'"
						[yAxisLabel]="'true'">
					</ngx-charts-bar-vertical>

					<ngx-charts-bar-vertical class="ChartBar"
						[view]="_arraySizeChartBarLarge"
						[scheme]="_modelDashboardProductHomeOwnershipCreditSummary.modelColorStatusRejected"
						[results]="_modelDashboardProductHomeOwnershipCreditSummary.listModelChartItemStatusRejected"
						[gradient]="false"
						[xAxis]="_booleanLegendShow"
						[yAxis]="_booleanLegendShow"
						[legend]="(_modelDashboardProductHomeOwnershipCreditSummary.listModelChartItemStatusRejected?.length ?? 0) < 10"
						[legendTitle]="'Ditolak'"
						[legendPosition]="_legendPositionBar"
						[showXAxisLabel]="_booleanLabelShow"
						[showYAxisLabel]="_booleanLabelShow"
						[xAxisLabel]="'true'"
						[yAxisLabel]="'true'">
					</ngx-charts-bar-vertical>
				</div>
			</div>

			<div id="divFormBuildingPrice" class="DivForm">
				<h3>Status Data Penutupan</h3>

				<div class="DivFormVerticalContainer">
					<ngx-charts-bar-vertical class="ChartBar"
						[view]="_arraySizeChartBarLarge"
						[scheme]="_modelDashboardProductHomeOwnershipCreditSummary.modelColorBuildingPrice"
						[results]="_modelDashboardProductHomeOwnershipCreditSummary.listModelChartItemBuildingPrice"
						[gradient]="false"
						[xAxis]="_booleanLegendShow"
						[yAxis]="_booleanLegendShow"
						[legend]="(_modelDashboardProductHomeOwnershipCreditSummary.listModelChartItemBuildingPrice?.length ?? 0) < 10"
						[legendTitle]="'Nilai Agunan'"
						[legendPosition]="_legendPositionBar"
						[showXAxisLabel]="_booleanLabelShow"
						[showYAxisLabel]="_booleanLabelShow"
						[xAxisLabel]="'true'"
						[yAxisLabel]="'true'">
					</ngx-charts-bar-vertical>
				</div>
			</div>
		</div>
	</div>
</div>