/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/prefer-for-of */
/* eslint-disable @typescript-eslint/no-unsafe-return */
//#region IMPORT

import { DatePipe } from "@angular/common";
import { Component, Injectable, ViewChild } from "@angular/core";
import { DateAdapter, MatDateFormats, MAT_DATE_FORMATS, NativeDateAdapter } from "@angular/material/core";
import { Router } from "@angular/router";
import { ENUM_RESPONSE_STATE, ENUM_SPPA_STATUS } from "src/app/constants/enum.constant";
import { NumberConstant } from "src/app/constants/number.constant";
import { ResponseCodeConstant } from "src/app/constants/responsecode.constant";
import { StringConstant } from "src/app/constants/string.constant";
import { TableModel } from "src/app/models/bases/table.model";
import { ProductGeneralModel } from "src/app/models/productgeneral.model";
import { ResponseModel } from "src/app/models/response.model";
import { ProducthomeownershipcreditService } from "src/app/services/producthomeownershipcredit.service";
import { SessionService } from "src/app/services/session.service";
import * as XLSX from "xlsx";
import { BaseAuthourizeComponent } from "../../bases/baseauthourize.component";
import { TableControlIncludeComponent } from "../../includes/tablecontrol.include/tablecontrol.include.component";

//#endregion


//#region CONST

export const appDateFormats: MatDateFormats =
{
	parse:
	{
		dateInput: { month: "short", year: "numeric", day: "numeric" },
	},
	display:
	{
		dateInput: "input",
		monthYearLabel: { year: "numeric", month: "numeric" },
		dateA11yLabel: { year: "numeric", month: "long", day: "numeric"
		},
		monthYearA11yLabel: { year: "numeric", month: "long" },
	}
};

//#endregion


//#region CLASS DECLARATION

@Injectable() export class AppDateAdapter extends NativeDateAdapter
{
	format(date: Date, displayFormat: Object): string
	{
		if (displayFormat === "input")
		{
			let stringDay: string = date.getUTCDate().toString();
			stringDay = +stringDay < 10 ? "0" + stringDay : stringDay;
			let stringMonth: string = (date.getUTCMonth() + 1).toString();
			stringMonth = +stringMonth < 10 ? "0" + stringMonth : stringMonth;
			const numberYear = date.getFullYear();
			return `${stringDay}/${stringMonth}/${numberYear}`;
		}
		return date.toDateString();
	}
}

//#endregion


//#region COMPONENT

@Component
(
	{
		selector: "app-reportlist",
		templateUrl: "./reportlist.component.html",
		styleUrls: ["./reportlist.component.sass"],
		providers:
		[
			DatePipe,
			{provide: DateAdapter, useClass: AppDateAdapter},
			{provide: MAT_DATE_FORMATS, useValue: appDateFormats}
		]
	}
)

//#endregion


//#region CLASS

export class ReportlistComponent extends BaseAuthourizeComponent
{
	//#region DECLARATION

	@ViewChild(TableControlIncludeComponent) private _componentTableControlInclude!: TableControlIncludeComponent;

	public _modelProductGeneralRequest: ProductGeneralModel;
	public _modelTable: TableModel = new TableModel();

	public _enumProductGeneralStatus = ENUM_SPPA_STATUS;

	public _stringDateView = StringConstant.STRING_DATE_VIEW_FORMAT;

	public _arrayProductGeneralModel: Array<ProductGeneralModel>;
	public _arrayExportProductGeneralModel: Array<ProductGeneralModel>;
	public _matrixExportExcel: any = [];

	//#endregion


	//#region CONSTRUCTOR

	constructor(private _serviceProductHomeOwnershipCredit: ProducthomeownershipcreditService, serviceSession: SessionService, public router: Router, private _datePipe: DatePipe)
	{
		super(serviceSession, router);
		this._modelProductGeneralRequest = new ProductGeneralModel();
		this._arrayProductGeneralModel = [];
		this._arrayExportProductGeneralModel = [];
	}

	//#endregion


	//#region WEB SERVICE

	callSelectProductByAttributes(componentCurrent: ReportlistComponent): void
	{
		this._functionUserInterface.setLoadingProgress(1);
		this._modelProductGeneralRequest.clearProductGeneralForProductHomeOwnershipCredit();
		const modelResponseValidation: ResponseModel = this._modelProductGeneralRequest.validateProductForExport();
		this._modelTable.Search = JSON.stringify(this._modelProductGeneralRequest);

		if (modelResponseValidation.State === ENUM_RESPONSE_STATE.Success)
		{
			this._serviceProductHomeOwnershipCredit.selectProductByAttributes
			({
				success(modelResponse: ResponseModel): void
				{
					if (modelResponse.ServiceResponseCode === ResponseCodeConstant.STRING_RESPONSECODE_SERVICE_SUCCESS)
					{
						if (modelResponse.Data !== undefined)
						{
							componentCurrent._functionUserInterface.updateLoadingProgress();
							componentCurrent._modelTable.setModelFromString(modelResponse.Data);

							if (componentCurrent._modelTable.Result !== undefined)
							{
								const arrayModelProductGeneralTemp = JSON.parse(componentCurrent._modelTable.Result);
								componentCurrent._modelTable.Result = undefined;
								componentCurrent._arrayProductGeneralModel = [];

								let modelProductGeneral: ProductGeneralModel;

								for (const modelProductGeneralTemp of arrayModelProductGeneralTemp)
								{
									modelProductGeneral = new ProductGeneralModel();
									modelProductGeneral.setModelFromObject(modelProductGeneralTemp);
									modelProductGeneral.setModelProductHomeOwnershipCredit();
									componentCurrent._arrayProductGeneralModel.push(modelProductGeneral);
								}

								componentCurrent._functionUserInterface.updateLoadingProgress();
								componentCurrent._componentTableControlInclude.setButtonState();
							}
							else
							{
								componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
							}
						}
						else
						{
							componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
						}
					}
					else
					{
						componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
					}
				},
				fail(modelResponse: ResponseModel): void
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponseWithRetry(modelResponse, () => { componentCurrent.callSelectProductByAttributes(componentCurrent); });
				},
				signOut(modelResponse: ResponseModel): void
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent.signOut(); });
				}
			}, this._modelTable);
		}
		else
		{
			this._functionUserInterface.showDialogFromModelResponse(modelResponseValidation, () => { });
		}
	};

	//#endregion


	//#region FUNCTION

	callExportClosingData(componentCurrent: ReportlistComponent): void
	{
		this._functionUserInterface.setLoadingProgress(2);
		const modelResponseValidation: ResponseModel = this._modelProductGeneralRequest.validateProductForExport();
		this._modelProductGeneralRequest.clearProductGeneralForProductHomeOwnershipCredit();

		if (modelResponseValidation.State === ENUM_RESPONSE_STATE.Success)
		{
			this._serviceProductHomeOwnershipCredit.selectProductForReport
			({
				success(modelResponse: ResponseModel): void
				{
					if (modelResponse.ServiceResponseCode === ResponseCodeConstant.STRING_RESPONSECODE_SERVICE_SUCCESS)
					{
						if (modelResponse.Data !== undefined)
						{
							componentCurrent._functionUserInterface.updateLoadingProgress();

							const arrayModelProductGeneralTemp = JSON.parse(modelResponse.Data);
							modelResponse.Data = undefined;
							componentCurrent._arrayExportProductGeneralModel = [];
							let modelProductGeneral: ProductGeneralModel;

							for (const modelProductGeneralTemp of arrayModelProductGeneralTemp)
							{
								modelProductGeneral = new ProductGeneralModel();
								modelProductGeneral.setModelFromObject(modelProductGeneralTemp);
								modelProductGeneral.setModelProductHomeOwnershipCredit();
								componentCurrent._arrayExportProductGeneralModel.push(modelProductGeneral);
							}

							for (let numberIndexRow = 0; numberIndexRow <= componentCurrent._arrayExportProductGeneralModel.length; numberIndexRow++)
							{
								componentCurrent._matrixExportExcel[numberIndexRow] = [];

								if (numberIndexRow === 0)
								{
									componentCurrent._matrixExportExcel[numberIndexRow].push("Nomor Rekening Pinjaman");
									componentCurrent._matrixExportExcel[numberIndexRow].push("Nama Debitur");
									componentCurrent._matrixExportExcel[numberIndexRow].push("Plafond");
									componentCurrent._matrixExportExcel[numberIndexRow].push("Tanggal Mulai Penutupan");
									componentCurrent._matrixExportExcel[numberIndexRow].push("Tanggal Akhir Penutupan");
									componentCurrent._matrixExportExcel[numberIndexRow].push("Okupasi");
									componentCurrent._matrixExportExcel[numberIndexRow].push("Alamat");
									componentCurrent._matrixExportExcel[numberIndexRow].push("Kode Pos");
									componentCurrent._matrixExportExcel[numberIndexRow].push("Tanggal Upload");
								}
								else
								{
									componentCurrent._matrixExportExcel[numberIndexRow].push(componentCurrent._arrayExportProductGeneralModel[numberIndexRow-NumberConstant.NUMBER_VALUE_POSITION_1].modelProductHomeOwnershipCredit?.LoanAccountNumber);
									componentCurrent._matrixExportExcel[numberIndexRow].push(componentCurrent._arrayExportProductGeneralModel[numberIndexRow-NumberConstant.NUMBER_VALUE_POSITION_1].modelProductHomeOwnershipCredit?.InsuredName);
									componentCurrent._matrixExportExcel[numberIndexRow].push(componentCurrent._arrayExportProductGeneralModel[numberIndexRow-NumberConstant.NUMBER_VALUE_POSITION_1].modelProductHomeOwnershipCredit?.BuildingPrice);

									const stringStartDate = componentCurrent._datePipe.transform(componentCurrent._arrayExportProductGeneralModel[numberIndexRow-NumberConstant.NUMBER_VALUE_POSITION_1].PolicyStartDate,"dd/MM/yyyy");
									componentCurrent._matrixExportExcel[numberIndexRow].push(stringStartDate);

									const stringEndDate = componentCurrent._datePipe.transform(componentCurrent._arrayExportProductGeneralModel[numberIndexRow-NumberConstant.NUMBER_VALUE_POSITION_1].PolicyEndDate,"dd/MM/yyyy");
									componentCurrent._matrixExportExcel[numberIndexRow].push(stringEndDate);

									componentCurrent._matrixExportExcel[numberIndexRow].push(componentCurrent._arrayExportProductGeneralModel[numberIndexRow-NumberConstant.NUMBER_VALUE_POSITION_1].modelProductHomeOwnershipCredit?.getOccupationRemarks());
									componentCurrent._matrixExportExcel[numberIndexRow].push(componentCurrent._arrayExportProductGeneralModel[numberIndexRow-NumberConstant.NUMBER_VALUE_POSITION_1].modelProductHomeOwnershipCredit?.RiskLocationRemarks);
									componentCurrent._matrixExportExcel[numberIndexRow].push(componentCurrent._arrayExportProductGeneralModel[numberIndexRow-NumberConstant.NUMBER_VALUE_POSITION_1].PolicyholderPostalCode);

									const stringUploadDate = componentCurrent._datePipe.transform(componentCurrent._arrayExportProductGeneralModel[numberIndexRow-NumberConstant.NUMBER_VALUE_POSITION_1].CreatedOn,"dd/MM/yyyy");
									componentCurrent._matrixExportExcel[numberIndexRow].push(stringUploadDate);
								}
							}

							/* generate worksheet */
							const workSheet: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(componentCurrent._matrixExportExcel, { cellDates:true, dateNF: "dd/mm/yyyy"});

							/* generate workbook and add the worksheet */
							const workBook: XLSX.WorkBook = XLSX.utils.book_new();
							XLSX.utils.book_append_sheet(workBook, workSheet, "Sheet1");

							/* save to file */
							XLSX.writeFile(workBook, "Report Excel.xlsx");

							const modelResponseExcel: ResponseModel = new ResponseModel();
							modelResponseExcel.MessageTitle = "Ekspor Excel";
							modelResponseExcel.MessageContent = "File excel berhasil di download! Silahkan tunggu.";
							componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponseExcel, () => {});

							componentCurrent._functionUserInterface.updateLoadingProgress();
						}
						else
						{
							componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
						}
					}
					else
					{
						componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
					}
				},
				fail(modelResponse: ResponseModel): void
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponseWithRetry(modelResponse, () => { componentCurrent.callExportClosingData(componentCurrent); });
				},
				signOut(modelResponse: ResponseModel): void
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent.signOut(); });
				}
			}, this._modelProductGeneralRequest);
		}
		else
		{
			this._functionUserInterface.showDialogFromModelResponse(modelResponseValidation, () => { });
		}
	}

	//#endregion


	//#region SETTER

	setEventEmitterSelect(modelTableUpdate: TableModel): void
	{
		this._modelTable = modelTableUpdate;
		this.callSelectProductByAttributes(this);
	}

	//#endregion


	//#region GETTER

	getStartDate(event): void
	{
		this._modelProductGeneralRequest.PolicyStartDate = event.value.clearUTC();
	};

	getEndDate(event): void
	{
		this._modelProductGeneralRequest.PolicyEndDate = event.value.clearUTC();
	};

	//#endregion


	//#region NAVIGATION

	goToDetail(stringToken?: string | null): void
	{
		if (stringToken == null || stringToken === undefined || stringToken === "")
		{
			this._router.navigate(["home", "sppa", "insert"]);
		}
		else
		{
			this._router.navigate(["home", "sppa", "detail", stringToken]);
		}
	}

	//#endregion

}

//#endregion