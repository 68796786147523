//#region IMPORT

import { environment } from "src/environments/environment";

//#endregion


//#region CLASS

export class WebAddressConstant
{
	//#region IPADDRESS

	static STRING_WEBADDRESS_WEBSITE_IPADRESS_BRINS: string = "www.brins.co.id";

	//#endregion


	//#region URL API

	static STRING_URL_USER_API: string = environment.URL_USER_API;
	static STRING_URL_PRODUCT_API: string = environment.URL_PRODUCT_API;
	static STRING_URL_POLICY_API: string = environment.URL_POLICY_API;
	static STRING_URL_BROADCAST_API: string = environment.URL_BROADCAST_API;
	static STRING_URL_INTEGRATION_API: string = environment.URL_INTEGRATION_API;

	//#endregion


	//#region URL WEBSITE SOCKET

	/*
	URL_USER_WEBSITESOCKET: String = BuildConfig.SCHEMA_WEBSITESOCKET + BuildConfig.HOST_USER_API + StringConstant.STRING_CHARACTER_SEPARATOR_TIME_VIEW + BuildConfig.PORT_USER;
	URLT_PRODUCT_WEBSITESOCKET: String = BuildConfig.SCHEMA_WEBSITESOCKET + BuildConfig.HOST_PRODUCT_API + StringConstant.STRING_CHARACTER_SEPARATOR_TIME_VIEW + BuildConfig.PORT_PRODUCT;
	URL_POLICY_WEBSITESOCKET: String = BuildConfig.SCHEMA_WEBSITESOCKET + BuildConfig.HOST_POLICY_API + StringConstant.STRING_CHARACTER_SEPARATOR_TIME_VIEW + BuildConfig.PORT_POLICY;
	*/

	//#endregion


	//#region HANDSHAKE SERVICE

	static STRING_PATH_CONTROLLER_HANDSHAKE: string = "/Handshake";

	static STRING_URL_HANDSHAKE_GENERATETOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_HANDSHAKE}/generateToken`;
	static STRING_URL_HANDSHAKE_REFRESHTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_HANDSHAKE}/refreshToken`;
	static STRING_URL_HANDSHAKE_SELECTUSERBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_HANDSHAKE}/selectUserByToken`;
	static STRING_URL_HANDSHAKE_REFRESHTOKENFORBRI: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_HANDSHAKE}/refreshTokenForBRI`;

	//#endregion


	//#region CONTROLLER DASHBOARD

	static STRING_PATH_CONTROLLER_DASHBOARD: string = "/Dashboard";

	static STRING_URL_DASHBOARD_SELECTDASHBOARDPRODUCTHOMEOWNERSHIPCREDIT: string = `${WebAddressConstant.STRING_URL_INTEGRATION_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DASHBOARD}/selectDashboardProductHomeOwnershipCredit`;

	//#endregion


	//#region BRI CONTROLLER

	static STRING_PATH_CONTROLLER_BRI: string = "/BRI";

	static STRING_URL_BRI_SELECTBRIBYEMAIL: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_BRI}/selectBRIByEmail`;
	static STRING_URL_BRI_VERIFYBRIBYEMAIL: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_BRI}/verifyBRIByEmail`;
	static STRING_URL_BRI_SIGNOUT: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_BRI}/signOut`;

	//#endregion


	//#region PRODUCT FIRE LONG TERM SYARIAH CONTROLLER

	static STRING_PATH_CONTROLLER_PRODCUTHOMEOWNERSHIPCREDIT: string = "/ProductHomeOwnershipCredit";

	static STRING_URL_PRODCUTHOMEOWNERSHIPCREDIT_SELECTPRODUCTBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_INTEGRATION_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODCUTHOMEOWNERSHIPCREDIT}/selectProductByAttributes`;
	static STRING_URL_PRODCUTHOMEOWNERSHIPCREDIT_SELECTPRODUCTBYTOKEN: string = `${WebAddressConstant.STRING_URL_INTEGRATION_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODCUTHOMEOWNERSHIPCREDIT}/selectProductByToken`;
	static STRING_URL_PRODCUTHOMEOWNERSHIPCREDIT_SELECTPRODUCTFORREPORT: string = `${WebAddressConstant.STRING_URL_INTEGRATION_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODCUTHOMEOWNERSHIPCREDIT}/selectProductForReport`;
	static STRING_URL_PRODCUTHOMEOWNERSHIPCREDIT_INSERTPRODUCT: string = `${WebAddressConstant.STRING_URL_INTEGRATION_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODCUTHOMEOWNERSHIPCREDIT}/insertProduct`;
	static STRING_URL_PRODCUTHOMEOWNERSHIPCREDIT_INSERTLISTPRODUCT: string = `${WebAddressConstant.STRING_URL_INTEGRATION_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODCUTHOMEOWNERSHIPCREDIT}/insertListProduct`;
	static STRING_URL_PRODCUTHOMEOWNERSHIPCREDIT_VALIDATELISTPRODUCT: string = `${WebAddressConstant.STRING_URL_INTEGRATION_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODCUTHOMEOWNERSHIPCREDIT}/validateListProduct`;
	static STRING_URL_PRODCUTHOMEOWNERSHIPCREDIT_CALLAPPROVEPRODUCTBYTOKEN: string = `${WebAddressConstant.STRING_URL_INTEGRATION_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODCUTHOMEOWNERSHIPCREDIT}/approveProductByToken`;
	static STRING_URL_PRODCUTHOMEOWNERSHIPCREDIT_CALLREJECTPRODUCTBYTOKEN: string = `${WebAddressConstant.STRING_URL_INTEGRATION_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODCUTHOMEOWNERSHIPCREDIT}/rejectProductByToken`;

	//#endregion


	//#region BRANCH CONTROLLER

	static STRING_PATH_CONTROLLER_BRANCH: string = "/Branch";

	static STRING_URL_BRANCH_SELECTBRANCH: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_BRANCH}/selectBranch`;

	//#endregion


	//#region DIVISION

	static STRING_PATH_CONTROLLER_DIVISION: string = "/Division";

	static STRING_URL_DIVISION_SELECTDIVISION: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DIVISION}/selectDivision`;

	//#endregion


	//#region CURRENCY

	static STRING_PATH_CONTROLLER_CURRENCY: string = "/Currency";

	static STRING_URL_CURRENCY_SELECTCURRENCY: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CURRENCY}/selectCurrency`;

	//#endregion


	//#region POSTAL

	static STRING_PATH_CONTROLLER_POSTAL: string = "/Postal";

	static STRING_URL_POSTAL_VALIDATEPOSTALCODE: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_POSTAL}/validatePostalCode`;
	static STRING_URL_POSTAL_SELECTLOCATIONBYPOSTALCODE: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_POSTAL}/selectLocationByPostalCode`;

	//#endregion
}

//#endregion