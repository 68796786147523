/* eslint-disable no-shadow */
//#region ENUM

//#region STATE

/*
	ENUM_REGISTRATION_STATE - START
	Description : Set of constants for registration state.
	Author :  Andri Septiawan.
	Created on : Monday, 28 December 2020.          Updated on : Monday, 28 December 2020.
	Created by : Andri Septiawan.					Updated by : Andri Septiawan.
	Version : 1.0:1.
*/

export enum ENUM_REGISTRATION_STATE
{
	Success = 0,
	Verified = 1,
	NotVerified = 2
}

/* ENUM_REGISTRATION_STATE - END */

/*
		ENUM_VERIFICATION_STATE - START
		Description : Set of constants for registration state.
		Author : Andri Septiawan.
		Created on : Monday, 28 December 2020.          Updated on : Monday, 28 December 2020.
		Created by : Andri Septiawan.					Updated by : Andri Septiawan.
		Version : 1.0:1.
	*/

export enum ENUM_VERIFICATION_STATE
{
	Verified = 0,
	OnProcess = 1,
	NotVerified = 2,
	Rejected = 3
}

/* ENUM_VERIFICATION_STATE - END */

/*
		ENUM_GENERAL_STATE - START
		Description : Set of constants for state general.
		Author : Andri Septiawan.
		Created on : Monday, 28 December 2020.          Updated on : Monday, 28 December 2020.
		Created by : Andri Septiawan.					Updated by : Andri Septiawan.
		Version : 1.0:1.
	*/

export enum ENUM_GENERAL_STATE
{
	Active = 0,
	Incative = 1
}

/* ENUM_GENERAL_STATE - END */

/*
		ENUM_RESPONSE_STATE - START
		Description : Enum for response state, this is used for validation response mostly, it will trigger the icon shown in the user interface.
		Author: Andri Septiawan.
		Created on :  Monday, 28 December 2020.             Updated on : Monday, 28 December 2020.
		Created by : Andri Septiawan.						Updated by : Andri Septiawan.
		Version : 1.0:1.
	*/

export enum ENUM_RESPONSE_STATE
{
	Success = 0,
	Warning = 1,
	Fail = 2,
	information = 3,
	Nothing = 4
}

/* ENUM_RESPONSE_STATE - END */

//#endregion


//#region TYPE

/*
		ENUM_GENDER_TYPE - START
		Description : Set of constants for gender type.
		Author : Andri Septiawan.
		Created on : Monday, 28 December 2020.          Updated on : Monday, 28 December 2020.
		Created by : Andri Septiawan.					Updated by : Andri Septiawan.
		Version : 1.0:1.
	*/

export enum ENUM_GENDER_TYPE
{
	Female = 0,
	Male = 1
}

/* ENUM_GENDER_TYPE - END */

/*
	ENUM_RELIGION_TYPE - START
	Description : Enum for religion state, these religion is based on Indonesia law.
	Author: Andri Septiawan.
	Created on : Monday, 28 December 2020.          Updated on : Monday, 28 December 2020.
	Created by : Andri Septiawan.					Updated by : Andri Septiawan.
	Version : 1.0:1.
*/

export enum ENUM_RELIGION_TYPE
{
	Islam = 0,
	Catholicism = 1,
	Christianity = 2,
	Hinduism = 3,
	Buddhism = 4,
	Confucianism = 5,

}

/* ENUM_RELIGION_FOLLOWER - END */

/*
		ENUM_RELIGION_FOLLOWER - START
		Description : Enum for religion state, these religion is based on Indonesia law.
		Author: Andri Septiawan.
		Created on : Monday, 28 December 2020.          Updated on : Monday, 28 December 2020.
		Created by : Andri Septiawan.					Updated by : Andri Septiawan.
		Version : 1.0:1.
	*/

export enum ENUM_RELIGION_FOLLOWER
{
	Moslem = 0,
	Catholic = 1,
	Christian = 2,
	Hindu = 3,
	Buddhist = 4,
	Confucianist = 5,
}

/* ENUM_RELIGION_FOLLOWER - END */

/*
		ENUM_IDENTIFICATION_TYPE - START
		Description : Enum for the identification type this will trigger how you treat the identification number.
		Author: Andri Septiawan.
		Created on : Monday, 28 December 2020.          Updated on : Monday, 28 December 2020.
		Created by : Andri Septiawan.					Updated by : Andri Septiawan.
		Version : 1.0:1.
	*/

export enum ENUM_IDENTIFICATION_TYPE
{
	IdentityCard = 0,
	DrivingLicense = 1,
	TemporaryStayCard = 2,
	PermanentStayCard = 3,
	Passport = 5
}

/* ENUM_IDENTIFICATION_TYPE - END */

/*
		ENUM_EDUCATION_TYPE - START
		Description : Enum for education type, these are the main education in Indonesia.
		Author: Andri Septiawan.
		Created on : Monday, 28 December 2020.          Updated on : Monday, 28 December 2020.
		Created by : Andri Septiawan.					Updated by : Andri Septiawan.
		Version : 1.0:1.
	*/

export enum ENUM_EDUCATION_TYPE
{
	ElementarySchool = 0,
	JuniorHighSchool = 1,
	SeniorHighSchool = 2,
	AssociateDegree = 3,
	BachelorDegree = 4,
	MasterDegree = 5,
	DoctoralDegree = 6
}

/* ENUM_EDUCATION_TYPE - END */

/*
		ENUM_EMPLOYMENT_TYPE - START
		Description : Enum for employee type, these are the main employement in company.
		Author: Andri Septiawan.
		Created on : Monday, 11 January 2021.          Updated on : Monday, 11 January 2021.
		Created by : Andri Septiawan.					Updated by : Andri Septiawan.
		Version : 1.0:1.
	*/

export enum ENUM_EMPLOYMENT_TYPE
{
	Volunteer = 0,
	Internship = 1,
	Freelance = 2,
	PartTime = 3,
	OutSource = 4,
	Contract = 5,
	FullTime = 6
}

/* ENUM_EMPLOYMENT_TYPE - END */

/*
		ENUM_EMPLOYMENT_TYPE - START
		Description : Enum for employee Grade, these are the main employement in company.
		Author: Andri Septiawan.
		Created on : Thursday, 18 February 2021.          Updated on : Thursday, 18 February 2021.
		Created by : Andri Septiawan.					Updated by : Andri Septiawan.
		Version : 1.0:1.
	*/

export enum ENUM_EMPLOYMENT_GRADE
{
	Zero = 0,
	One = 1,
	Two = 2,
	Three = 3,
	Four = 4,
	Five = 5,
	Six = 6,
	Seven = 7,
	Eight = 8,
	Nine = 9,
	Ten = 10,
	Eleven = 11,
	Twelve = 12,
	Thirteen = 13,
	Fourteen = 14,
	Fifteen = 15
}

/* ENUM_EMPLOYMENT_TYPE - END */


/*
		ENUM_SERVER_TYPE - START
		Description : Enum for server name, this will be use for micro service architecure, to know where the latest token is.
		Author: Andri Septiawan.
		Created on : Monday, 28 December 2020.          Updated on : Monday, 28 December 2020.
		Created by : Andri Septiawan.					Updated by : Andri Septiawan.
		Version : 1.0:1.
	*/

export enum ENUM_SERVER_TYPE
{
	user,
	product,
	Policy
}

/* ENUM_SERVER_TYPE - END */

/*
		ENUM_REGISTER_TYPE - START
		Description : Enum for server name, this will be use for micro service architecure, to know where the latest token is.
		Author: Andri Septiawan.
		Created on : Monday, 28 December 2020.          Updated on : Monday, 28 December 2020.
		Created by : Andri Septiawan.					Updated by : Andri Septiawan.
		Version : 1.0:1.
	*/

export enum ENUM_REGISTER_TYPE
{
	KenalanMobilePhone = 0,
	Facebook = 1,
	Google = 2,
	Linkedin = 3,
	Apple = 4,
	Twitter = 5,
	Line = 6,
	KakaoTalk = 7,
	KenalanEmail = 8,
}

/* ENUM_REGISTER_TYPE - END */

/*
		ENUM_SALUTATION_TYPE - START
		Description : Set of constants for salutation type.
		Author : Andri Septiawan.
		Created on : Monday, 28 December 2020.          Updated on : Monday, 28 December 2020.
		Created by : Andri Septiawan.						Updated by : Andri Septiawan.
		Version : 1.0:1.
	*/

export enum ENUM_SALUTATION_TYPE
{
	Miss,
	Mr,
	Mrs
}

/* ENUM_SALUTATION_TYPE - END */

/*
		ENUM_PHOTO_TYPE - START
		Description : Set of constants for photo type accross product.
		Author : Andri Septiawan.
		Created on : Monday, 28 December 2020.          Updated on : Monday, 28 December 2020.
		Created by : Andri Septiawan.					Updated by : Andri Septiawan.
		Version : 1.0:1.
	*/

export enum ENUM_PHOTO_TYPE
{
	BuildingFront,
	BuildingLeft,
	BuildingRight,
	BuildingBack,
	BuildingInterior,
	VehicleFront,
	VehicleLeft,
	VehicleRight,
	VehicleBack,
	VehicleInterior,
	STNK,
	KTP,
	ClientApproval,
	PaymentReceipt,
	Visit
}

/* ENUM_PHOTO_TYPE - END */

/*
		ENUM_ENVIRONMENT_TYPE - START
		Description : Set of constants for environment type.
		Author : Andri Septiawan.
		Created on : Monday, 28 December 2020.          Updated on : Monday, 28 December 2020.
		Created by : Andri Septiawan.						Updated by : Andri Septiawan.
		Version : 1.0:1.
	*/

export enum ENUM_ENVIRONMENT_TYPE
{
	Local = 0,
	Development = 1,
	Staging = 2,
	Production = 3,
}

/* ENUM_ENVIRONMENT_TYPE - END */

/*
		ENUM_PROJECT_TYPE - START
		Description : Set of constants for project type.
		Author : Andri Septiawan.
		Created on : Monday, 28 December 2020.          Updated on : Monday, 28 December 2020.
		Created by : Andri Septiawan.						Updated by : Andri Septiawan.
		Version : 1.0:1.
	*/

export enum ENUM_PROJECT_TYPE
{
	Anjani,
	Bromo,
	Staging,
	Production
}

/* ENUM_PROJECT_TYPE - END */

/*
		ENUM_VIRTUALACCOUNT_TYPE - START
		Description : Set of constants for virtual account type.
		Author : Andri Septiawan.
		Created on : Monday, 28 December 2020.          Updated on : Monday, 28 December 2020.
		Created by : Andri Septiawan.						Updated by : Andri Septiawan.
		Version : 1.0:1.
	*/

export enum ENUM_VIRTUALACCOUNT_TYPE
{
	Briva = 0
}

/* ENUM_VIRTUALACCOUNT_TYPE - END */

/*
		ENUM_PDFPREVIEW_TYPE - START
		Description : Set of constants for pdf preview type, to define what model to open from product.
		Author : Andri Septiawan.
		Created on : Monday, 28 December 2020.          Updated on : Monday, 28 December 2020.
		Created by : Andri Septiawan.						Updated by : Andri Septiawan.
		Version : 1.0:1.
	*/

export enum ENUM_PDFPREVIEW_TYPE
{
	ProductAsriCalculatePremium = 0,
	ProductAsriSPPA = 1,
	ProductOtoCalculatePremium = 2,
	ProductOtoSPPA = 3,
	ProductPersonalAccidentCalculatePremium = 4,
	ProductPersonalAccidentSPPA = 5,
	PolicyOutstanding = 6,
	PolicyRenewal = 7
}

/* ENUM_PDFPREVIEW_TYPE - END */

/*
	ENUM_LETTER_TYPE - START
	Description : Set of constants for letter type, to define what serial number need to be generated.
	Author : Andri Septiawan.
	Created on : Monday, 28 December 2020.          	Updated on : Friday, 16 April 2021.
	Created by : Andri Septiawan.						Updated by : Billy Johannes.
	Version : 1.1:0.
*/

export enum ENUM_LETTER_TYPE
{
	DeclarationPayment = 1,
	DeclarationReimburse = 2,
	DeclarationRealizationDownPayment = 3,
	DeclarationRealizationDebt = 4,
	SubmissionDownPayment = 5,
	SubmissionDebt = 6,
	OfficialMemo = 7,
	LetterOut = 8,
	LetterIn = 9,
	Outstanding = 10,
	Renewal = 11
}

/* ENUM_LETTER_TYPE - END */

/*
		ENUM_BRANCH_TYPE - START
		Description : Set of constants for branch type, to define what branch type included in.
		Author : Billy Johannes.
		Created on : Sunday, 02 May 2020.          	Updated on : Sunday, 02 May 2020.
		Created by : Billy Johannes.				Updated by : Billy Johannes.
		Version : 1.0:0.
	*/

export enum ENUM_BRANCH_TYPE
{
	Headquarters = 0,
	Branch = 1,
	MRO = 2,
	ROS = 3
}

/* ENUM_BRANCH_TYPE - END */

/*
	ENUM_PRODUCT_TYPE - START
	Description : Set of constants for product type, to use in search by attributes.
	Author : Ibrahim Aziz.
	Created on : Tuesday, 13 April 2021.   Updated on : Tuesday, 13 April 2021.
	Created by : Ibrahim Aziz.      Updated by : Ibrahim Aziz.
	Version : 1.0:1.
*/

export enum ENUM_PRODUCT_TYPE
{
	Conventional = 0,
	Special = 1,
	Syariah = 2,
	Customize = 3
}

/* ENUM_PRODUCT_TYPE - END */

/*
	ENUM_PRODUCTFIRELONGTERMSYARIAH_STATUS - START
	Description : Set of constants for product fire long term status.
	Author : Billy Johannes.
	Created on : Thrusday, 22 July 2021.          	Updated on : Thrusday, 22 July 2021.
	Created by : Billy Johannes.					Updated by : Billy Johannes.
	Version : 1.0:0.
*/

export enum ENUM_PRODUCTFIRELONGTERMSYARIAH_STATUS
{
	PendingForApproval = 0,
	Rejected = 1,
	Approved = 2,
	Sent = 3
}

/* ENUM_PRODUCTFIRELONGTERMSYARIAH_STATUS - END */

/*
	ENUM_MARITAL_STATUS - START
	Description : Set of constants for marital status.
	Author : Andri Septiawan.
	Created on : Monday, 28 December 2020.          Updated on : Monday, 28 December 2020.
	Created by : Andri Septiawan.					Updated by : Andri Septiawan.
	Version : 1.0:1.
*/

export enum ENUM_MARITAL_STATUS
{
	Single = 0,
	Married = 1,
	Divorce = 2,
	Widow = 3,
}

/* ENUM_MARITAL_STATUS - END */

/*
		ENUM_HTTP_STATUS - START
		Description : List of HTTP response code this for the history and response to our service.
		Author: Andri Septiawan.
		Created on : Monday, 28 December 2020.          Updated on : Monday, 28 December 2020.
		Created by : Andri Septiawan.				    Updated by : Andri Septiawan.
		Version : 1.0:1.
	*/

export enum ENUM_HTTP_STATUS
{
	/* INFORMATIONAL - START*/

	Continue = 100,
	SwitchingProtocol = 101,
	Processing = 102,

	/* INFORMATIONAL - END */

	/* SUCCESS - START */

	Ok = 200,
	Created = 201,
	Accepted = 202,
	NonAuthorativeInformation = 203,
	NoContent = 204,
	ResetContent = 205,
	PartialContent = 206,
	MultiStatus = 207,
	AlreadyReported = 208,
	IMUsed = 226,

	/* SUCCESS - END */

	/* REDIRECTION - START */

	MultipleChoices = 300,
	MovedPermanently = 301,
	Found = 302,
	SeeOther = 303,
	NotModified = 304,
	UseProxy = 305,
	Unused = 306,
	TemporaryRedirect = 307,
	PermanentRedirection = 308,

	/* REDIRECTION - END */

	/* CLIENT ERROR - START */

	BadRequest = 400,
	Unauthorized = 401,
	PaymentRequired = 402,
	Forbidden = 403,
	NotFound = 404,
	MethodNotAllowed = 405,
	NotAcceptable = 406,
	ProxyAuthenticationRequired = 407,
	RequestTimeout = 408,
	Conflict = 409,
	Gone = 410,
	LengthRequired = 411,
	PreconditionFailed = 412,
	RequestEntityTooLarge = 413,
	RequestURITooLong = 414,
	UnsupportedMediaType = 415,
	RequestedRangeNotSatisfiable = 416,
	ExpectationFailed = 417,
	ImATeapot = 418,
	EnhanceYourCalm = 420,
	UnprocessableEntity = 422,
	Locked = 423,
	FailedDependency = 424,
	ReservedForWebDAV = 425,
	UpgradeRequired = 426,
	PreconditionRequires = 428,
	TooManyRequest = 429,
	RequestHeaderFieldsTooLarge = 431,
	NoResponse = 444,
	RetryWith = 449,
	BlockedByWindowsParentalControls = 450,
	UnavailableForLegalReasons = 451,
	ClientClosedRequest = 499,
	BlockedByCORS = 0,

	/* CLIENT ERROR - END */

	/* SERVER ERROR - START */

	InternalServerError = 500,
	NotImplemented = 501,
	BadGateway = 502,
	ServiceUnavailable = 503,
	GatewayTimeout = 504,
	HTTPVersionNotSupported = 501,
	VariantAlsoNegotiates = 506,
	InsufficientStorage = 507,
	LoopDetected = 508,
	BandwithLimitExceeded = 509,
	NotExtended = 510,
	NetworkAuthenticationRequired = 511,
	NetworkReadTimeoutError = 598,
	NetworkConnectTimeoutError = 599

	/* SERVER ERROR - END */
}

/* ENUM_HTTP_STATUS - END */

/*
		ENUM_SURVEY_STATUS - START
		Description : Set of constants for survey status.
		Author : Andri Septiawan.
		Created on : Monday, 28 December 2020.          Updated on : Monday, 28 December 2020.
		Created by : Andri Septiawan.						    Updated by : Andri Septiawan.
		Version : 1.0:1.
	*/

export enum ENUM_SURVEY_STATUS
{
	Pending = 0,
	Approved = 1,
	Rejected = 2,
}

/* ENUM_SURVEY_STATUS - END */

/*
		ENUM_UNDERWRITING_STATUS - START
		Description : Set of constants for underwriting status.
		Author : Andri Septiawan.
		Created on : Monday, 28 December 2020.          Updated on : Monday, 28 December 2020.
		Created by : Andri Septiawan.						    Updated by : Andri Septiawan.
		Version : 1.0:1.
	*/

export enum ENUM_UNDERWRITING_STATUS
{
	Pending = 0,
	Approved = 1,
	Rejected = 2,
}

/* ENUM_UNDERWRITING_STATUS - END */

/*
		ENUM_SPPA_STATUS - START
		Description : Set of constants for sppa status.
		Author : Andri Septiawan.
		Created on : Monday, 28 December 2020.          Updated on : Monday, 28 December 2020.
		Created by : Andri Septiawan.						    Updated by : Andri Septiawan.
		Version : 1.0:1.
	*/

export enum ENUM_SPPA_STATUS
{
	OnProgress = 0,
	ReadyForSave = 1,
	Saved = 2,
	Approved = 3,
	PendingForSurvey = 4,
	RejectedSurvey = 5,
	PendingForUnderwriting = 6,
	RejectedUnderwritting = 7,
	PendingForPayment = 8,
	Paid = 9,
	Completed = 10
}

/* ENUM_SPPA_STATUS - END */

/*
	ENUM_POLICY_STATUS - START
	Description : Set of constants for policy status.
	Author : Andri Septiawan.
	Created on : Monday, 28 December 2020.          Updated on : Monday, 28 December 2020.
	Created by : Andri Septiawan.						    Updated by : Andri Septiawan.
	Version : 1.0:1.
*/

export enum ENUM_POLICY_STATUS
{
	Active = 1,
	Outstanding = 2,
	Inactive = 3,
	Expired = 4,
	Terminated = 5,
	Canceled = 6,
	Pending = 7
}

/* POLICY_STATUS - END */

//#endregion


//#region MISELANIOUS

/*
	ENUM_FONT_FAMILY - START
	Description : This will be tight to user model, to set up the font family preference.
	Author: Andri Septiawan.
	Created on : Monday, 28 December 2020.          Updated on : Monday, 28 December 2020.
	Created by : Andri Septiawan.					Updated by : Andri Septiawan.
	Version : 1.0:1.
*/

export enum ENUM_FONT_FAMILY
{
	Primary = 0,
	Second = 1
}

/* ENUM_FONT_FAMILY - END */

/*
		ENUM_FONT_SIZE - START
		Description : This will be tight to user model, to set up the font size preference.
		Author: Andri Septiawan.
		Created on : Monday, 28 December 2020.          Updated on : Monday, 28 December 2020.
		Created by : Andri Septiawan.					Updated by : Andri Septiawan.
		Version : 1.0:1.
	*/

export enum ENUM_FONT_SIZE
{
	Small = 0,
	Medium = 1,
	Large = 2
}

/* ENUM_FONT_SIZE - END */

/*
		ENUM_THEME_COLOUR - START
		Description : This will be tight to user model, to set up the theme color preference.
		Author: Andri Septiawan.
		Created on : Monday, 28 December 2020.          Updated on : Monday, 28 December 2020.
		Created by : Andri Septiawan.					Updated by : Andri Septiawan.
		Version : 1.0:1.
	*/

export enum ENUM_THEME_COLOUR
{
	Fioralba = 0,
	Aurora = 1
}

/* ENUM_THEME_COLOUR - END */

/*
	ENUM_DATE_MONTH - START
	Description : This will be used for spinner date.
	Author: Andri Septiawan.
	Created on : Monday, 28 December 2020.          Updated on : Monday, 28 December 2020.
	Created by : Andri Septiawan.					Updated by : Andri Septiawan.
	Version : 1.0:1.
*/

export enum ENUM_DATE_MONTH
{
	January = 1,
	February = 2,
	March = 3,
	April = 4,
	May = 5,
	June = 6,
	July = 7,
	August = 8,
	September = 9,
	October = 10,
	November = 11,
	December = 12
}

/* ENUM_DATE_MONTH - END */

/*
	ENUM_LETTER_STATUS - START
	Description : This role user in kartini website.
	Author: Billy Johannes.
	Created on : Friday, 19 March 2021.        		Updated on : Friday, 16 April 2021..
	Created by : Billy Johannes.					Updated by : Billy Johannes.
	Version : 1.1:1.
*/

export enum ENUM_LETTER_STATUS
{
	OnProgress = 0,
	PendingForCheck = 1,
	PendingForSign = 2,
	Rejected = 3,
	Signed = 4,
	Printed = 5,
	Sent = 6
}

/* ENUM_LETTER_STATUS - END */

/*
		ENUM_LETTER_APPROVAL - START
		Description : This role user in kartini website.
		Author: Billy Johannes.
		Created on : Tuesday, 04 May 2021.        	Updated on : Tuesday, 04 May 2021.
		Created by : Billy Johannes.				Updated by : Billy Johannes.
		Version : 1.0:0.
	*/

export enum ENUM_LETTER_APPROVAL
{
	Pending = 1,
	Approve = 2,
	Reject = 3,
}

/*  ENUM_LETTER_APPROVAL - END */

/*
	ENUM_LETTERROLE_TYPE - START
	Description : For categorized and role user in kartini website.
	Author: Billy Johannes.
	Created on : Friday, 16 April 2021.        		Updated on : Friday, 16 April 2021.
	Created by : Billy Johannes.					Updated by : Billy Johannes.
	Version : 1.0:0.
*/

export enum ENUM_LETTER_ROLE
{
	Maker = 0,
	Checker = 1,
	Signer = 2
}

/* ENUM_LETTERROLE_TYPE - END */

/*
	ENUM_DIVISION_ID - START
	Description : This division id used accross website to determine division validation process.
	Author: Billy Johannes.
	Created on : Friday, 28 May 2021.        		Updated on : Friday, 28 May 2021.
	Created by : Billy Johannes.					Updated by : Billy Johannes.
	Version : 1.0:0.
*/

export enum ENUM_DIVISION_ID
{
	NoDivision = 0,
	OperationAndSupport = 15,
}

/* ENUM_DIVISION_ID - END */

/*
	ENUM_TICKET_STATUS - START
	Description : Set of constants for policy status.
	Author : Ibrahim Aziz.
	Created on : Tuesday, 13 April 2021.           Updated on : Monday, 28 December 2020.
	Created by : Ibrahim Aziz.      Updated by : Ibrahim Aziz.
	Version : 1.0:2.
*/

export enum ENUM_TICKET_STATUS
{
	Pending = 0,
	Open = 1,
	ClosedWithConfirmation = 2,
	ClosedWithoutConfirmation = 3,
	NotClosedWithConfirmation = 4,
	NotClosedWithoutConfirmation = 5,
	Canceled = 6
}

/* ENUM_TICKET_STATUS - END */


/*
	ENUM_PAYMENT_TYPE - START
	Description : For payment type in BRINESIA environment.
	Author: Billy Johannes.
	Created on : Tuesday, 23 March 2021.        		Updated on : Tuesday, 23 March 2021.
	Created by : Billy Johannes.						Updated by : Billy Johannes.
	Version : 1.0:0.
*/

export enum ENUM_PAYMENT_TYPE
{
	Cash = 0,
	Transfer = 1
}

/* ENUM_PAYMENT_TYPE - END */

/*
	ENUM_PAYMENTPRIORITY_TYPE - START
	Description : For payment priority type in BRINESIA environment.
	Author: Billy Johannes.
	Created on : Tuesday, 23 March 2021.        		Updated on : Tuesday, 23 March 2021.
	Created by : Billy Johannes.						Updated by : Billy Johannes.
	Version : 1.0:0.
*/

export enum ENUM_PAYMENTPRIORITY_TYPE
{
	Normal = 0,
	Important = 1,
	VeryImportant = 2
}

/* ENUM_PAYMENTPRIORITY_TYPE - END */

/*
    ENUM_PLATFORM_TYPE - START
    Description : Set of constants for platform type for analytics and issue tracking.
    Author : Andri Septiawan.
    Created on : Friday, 15 January 2021.   Updated on : Friday, 15 January 2021.
    Created by : Andri Septiawan.          	Updated by : Andri Septiawan.
    Version : 1.0:1.
*/

export enum ENUM_PLATFORM_TYPE
{
	Windows = 0,
	Mac = 1,
	Linux = 2,
	Android = 3,
	IOS = 4
}

/* ENUM_PLATFORM_TYPE - END */

/*
    ENUM_DEVICE_TYPE - START
    Description : Set of constants for device type for analytics and issue tracking.
    Author : Andri Septiawan.
    Created on : Friday, 15 January 2021.   Updated on : Friday, 15 January 2021.
    Created by : Andri Septiawan.          	Updated by : Andri Septiawan.
    Version : 1.0:1.
*/

export enum ENUM_DEVICE_TYPE
{
	Desktop = 0,
	Website = 1,
	Handphone = 2,
	Tablet = 3
}

/* ENUM_DEVICE_TYPE - END */

/*
    ENUM_SORT_TYPE - START
    Description : Set of constants for device type for analytics and issue tracking.
    Author : Andri Septiawan.
    Created on : Thursday, 18 February 2021.   	Updated on : Thursday, 18 February 2021.
    Created by : Andri Septiawan.          		Updated by : Andri Septiawan.
    Version : 1.0:1.
*/

export enum ENUM_SORT_TYPE
{
	Ascending = 0,
	Descending = 1,
}

/* ENUM_SORT_TYPE - END */


/*
	ENUM_TOPIC_TYPE - START
	Description : Set of constants for ticket topic type for ticket.
	Author : Billy Johannes.
	Created on : Monday, 24 May 2021.          	Updated on : Monday, 24 May 2021.
	Created by : Billy Johannes.				Updated by : Billy Johannes.
	Version : 1.0:0.
*/

export enum ENUM_TOPIC_TYPE
{
	SignIn = 0,
	PremiumCalculation = 1,
	Payment = 2,
	Claim = 3,
	Policy = 4,
	ProductInsurance = 5,
	BRINSSMART = 6,
	Other = 7
}

/* ENUM_TOPIC_TYPE - END */

/*
	ENUM_SEVERITY_TYPE - START
	Description : Set of constants for severity status for ticket.
	Author : Ibrahim Aziz.
	Created on : Tuesday, 13 April 2021.          	Updated on : Tuesday, 13 April 2021.
	Created by : Ibrahim Aziz.						Updated by : Ibrahim Aziz.
	Version : 1.0:1.
*/

export enum ENUM_SEVERITY_TYPE
{
	Low = 0,
	Medium = 1,
	High = 2,
	Urgent = 3
}

/* ENUM_SEVERITY_TYPE - END */

/*
	ENUM_TICKETCHAT_TYPE - START
	Description : Set of constants for ticket chat type for ticket.
	Author : Ibrahim Aziz.
	Created on : Tuesday, 13 April 2021.          	Updated on : Tuesday, 13 April 2021.
	Created by : Ibrahim Aziz.						Updated by : Ibrahim Aziz.
	Version : 1.0:1.
*/

export enum ENUM_TICKETCHAT_TYPE
{
	Private = 0,
	Public = 1
}

/* ENUM_TICKETCHAT_TYPE - END */

/*
	ENUM_OCCUPATIONREMARKS_TYPE - START
	Description : Set of constants for occupation remarks type for ticket.
	Author : Billy Johannes.
	Created on : Thursday, 26 August 2021.          Updated on : Thursday, 13 August 2021.
	Created by : Billy Johannes.					Updated by : Billy Johannes.
	Version : 1.0:0.
*/

export enum ENUM_OCCUPATIONREMARKS_TYPE
{
	LandedHouse = 0,
	FlatHouse = 1
}

/* ENUM_OCCUPATIONREMARKS_TYPE - END */

//#endregion

//#endregion