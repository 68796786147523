//#region IMPORT

import { ENUM_GENDER_TYPE, ENUM_IDENTIFICATION_TYPE, ENUM_OCCUPATIONREMARKS_TYPE, ENUM_PROJECT_TYPE, ENUM_RESPONSE_STATE, ENUM_SPPA_STATUS } from "../constants/enum.constant";
import { NumberConstant } from "../constants/number.constant";
import { StringConstant } from "../constants/string.constant";
import { GeneralModel } from "./bases/general.model";
import { LeadModel } from "./lead.model";
import { PostalModel } from "./postal.model";
import { ProductAsriModel } from "./productasri.model";
import { ProductAsriSyariahModel } from "./productasrisyariah.model";
import { ProductHomeOwnershipCreditModel } from "./producthomeownershipcredit.model";
import { ProductOtoModel } from "./productoto.model";
import { ProductOtoSyariahModel } from "./productotosyariah.model";
import { ProductPersonalAccidentModel } from "./productpersonalaccident.model";
import { ProductPersonalAccidentSyariahModel } from "./produtpersonalaccidentsyariah.model";
import { ResponseModel } from "./response.model";
import { UserModel } from "./user.model";
import { ValidateModel } from "./validate.model";
import { VirtualAccountModel } from "./virtualaccount.model";

//#endregion


//#region CLASS

export class ProductGeneralModel extends GeneralModel
{
	/*
		Attribute - START
		Description : Attribute for product general model, used for contain general information to cover specific product.
		Author: Andri Septiawan and Ibrahim Aziz.
		Created on : Tuesday, 29 December 2020.		    	Updated on : Wednesday, 6 January 2021.
		Created by : Andri Septiawan.						Updated by : Ibrahim Aziz.
		Version : 1.0:1.
	*/

	/* POLICY DETAIL - START */

	ID?: number;
	ProductCode?: string;
	ProductName?: string;
	ReferenceNumber?: string;
	PolicyNumber?: string;
	PolicyID?: number;
	Status?: ENUM_SPPA_STATUS;
	PolicyStartDate?: Date;
	PolicyEndDate?: Date;
	LeadToken?: string;

	CustomerID?: number;
	RelationshipManagerID?: number;
	Token?: string;

	Disclaimer?: boolean;
	Note?: string;
	ProjectSource?: ENUM_PROJECT_TYPE;

	/* POLICY DETAIL - END */

	/* POLICYHOLDER - START */

	PolicyholderFirstName?: string;
	PolicyholderMiddleName?: string;
	PolicyholderLastName?: string;
	PolicyholderBirthDate?: Date;
	PolicyholderBirthPlace?: string;
	PolicyholderGender?: ENUM_GENDER_TYPE;
	PolicyholderTelephone?: string;
	PolicyholderMobilePhone?: string;
	PolicyholderEmail?: string;

	PolicyholderIdentificationNumber?: string;
	PolicyholderIdentificationType?: ENUM_IDENTIFICATION_TYPE;

	PolicyholderAddress?: string;
	PolicyholderCountryID?: number;
	PolicyholderProvinceID?: number;
	PolicyholderCityID?: number;
	PolicyholderSubDistrictID?: number;
	PolicyholderVillageID?: number;
	PolicyholderHamletCode?: string;
	PolicyholderNeighbourhoodCode?: string;
	PolicyholderPostalID?: number;
	PolicyholderPostalCode?: string;
	PolicyholderLatitude?: number;
	PolicyholderLongitude?: number;

	/* POLICYHOLDER - END */

	/* PAYMENT - START */

	PremiumCurrency?: string;
	PremiumAmount?: number;

	DiscountCode?: string;
	DiscountCurrency?: string;
	DiscountPercent?: string;
	DiscountAmount?: number;

	CommissionCode?: string;
	CommissionPercent?: number;
	CommissionCurrency?: string;
	CommmisionAmount?: number;

	AdministrationAmount?: number;
	StampAmount?: number;
	PremiumTotalAmount?: number;

	InvoiceURL?: string;
	InvoiceExpiredDate?: Date;
	InvoiceID?: string;
	CoverageCode?: string;
	PaymentAccountID?: number;

	VirtualAccountID?: number;

	/* PAYMENT - END */

	/* TRACKING - START */

	SavedBy?: number;
	SavedOn?: Date;
	SubmittedBy?: number;
	SubmittedOn?: Date;
	SurveyedBy?: number;
	SurveyedOn?: Date;
	ReviewedBy?: number;
	ReviewedOn?: Date;
	PaidBy?: number;
	PaidOn?: Date;
	CompletedBy?: number;
	CompletedOn?: Date;

	/* STATUS ATTRIBUTE - START */

	ApprovedOn?: Date;
	ApprovedBy?: string;
	RejectedOn?: Date;
	RejectedBy?: number;
	RejectedNote?: string;
	UpdatedOn?: Date;

	/* STATUS ATTRIBUTE - END */

	/* TRACKING - END */

	/* CARE TECH - START */

	ANO?: string;
	INO?: string;
	SourceID?: string;

	/* CARE TECH - END */

	/* NOT MAPPED - START */

	modelUser?: UserModel;
	modelProductAsri?: ProductAsriModel;
	modelProductAsriSyariah?: ProductAsriSyariahModel;
	modelProductOto?: ProductOtoModel;
	modelProductOtoSyariah?: ProductOtoSyariahModel;
	modelProductPersonalAccident?: ProductPersonalAccidentModel;
	modelProductPersonalAccidentSyariah?: ProductPersonalAccidentSyariahModel;
	modelProductHomeOwnershipCredit?: ProductHomeOwnershipCreditModel;
	modelLead?: LeadModel;
	modelVirtualAccount?: VirtualAccountModel;

	stringBirthDate?: string;

	/* NOT MAPPED - END */

	/* Attribute - END */


	//#region CONSTRUCTOR

	constructor()
	{
		super();

		this.modelProductAsri = new ProductAsriModel();
		this.modelProductAsriSyariah = new ProductAsriSyariahModel();
		this.modelProductOto = new ProductOtoModel();
		this.modelProductOtoSyariah = new ProductOtoSyariahModel();
		this.modelProductPersonalAccident = new ProductPersonalAccidentModel();
		this.modelProductPersonalAccidentSyariah = new ProductPersonalAccidentSyariahModel();
		this.modelProductHomeOwnershipCredit = new ProductHomeOwnershipCreditModel();
	}

	//#endregion


	//#region SETTER

	setModelProductHomeOwnershipCredit(): void
	{
		const stringModelProductHomeOwnershipCredit: string = JSON.stringify(this.modelProductHomeOwnershipCredit);
		this.modelProductHomeOwnershipCredit = new ProductHomeOwnershipCreditModel();
		this.modelProductHomeOwnershipCredit.setModelFromString(stringModelProductHomeOwnershipCredit);
	}

	//#endregion


	//#region FUNCTION

	premiumTotalAmount(): void
	{
		if (this.AdministrationAmount !== undefined && this.StampAmount !== undefined && this.PremiumAmount !== undefined)
		{
			this.PremiumTotalAmount = this.AdministrationAmount + this.StampAmount + this.PremiumAmount;
		}
		else
		{

		}
	}

	//#endregion


	//#region VALIDATE

	validateForProduct(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();

		if (this.VehicleBrandCode == null || this.VehicleBrandCode === undefined || this.VehicleBrandCode === "")
		{
			modelResponse.MessageContent = "Model Vehicle Code can't be empty.";
			return modelResponse;
		}
		else
		{
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
			modelResponse.MessageContent = "Form is filled correctly.";
			return modelResponse;
		}
	}

	validatePolicyholderEmail(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.setForValidation("User");
		const regularExpression: RegExp = new RegExp(StringConstant.STRING_REGULAREXPRESSION_EMAIL);

		if (this.PolicyholderEmail == null || this.PolicyholderEmail === undefined || this.PolicyholderEmail === "")
		{
			modelResponse.MessageContent = "Email can't be empty";
		}
		else if (regularExpression.test(this.PolicyholderEmail))
		{
			modelResponse.MessageContent = "Form is filled correctly.";
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
		}
		else
		{
			modelResponse.MessageContent = "Enter a valid email.";
		}

		return modelResponse;
	}

	validateProductForInsert(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.setForValidation("Product General");

		if (this.modelProductHomeOwnershipCredit?.LoanAccountNumber == null || this.modelProductHomeOwnershipCredit?.LoanAccountNumber === undefined || this.modelProductHomeOwnershipCredit?.LoanAccountNumber === "")
		{
			modelResponse.MessageContent = "Nomor rekening tidak boleh kosong! Silahkan isi!";
		}
		else if (this.modelProductHomeOwnershipCredit?.LoanAccountNumber?.search(/^[-+]?[0-9]+$/))
		{
			modelResponse.MessageContent = "Nomor rekening tidak boleh mengandung huruf! Silahkan isi dengan benar!";
		}
		else if (this.modelProductHomeOwnershipCredit?.InsuredName == null || this.modelProductHomeOwnershipCredit?.InsuredName === undefined || this.modelProductHomeOwnershipCredit?.InsuredName === "")
		{
			modelResponse.MessageContent = "Nama debitur tidak boleh kosong! Silahkan isi!";
		}
		else if (this.modelProductHomeOwnershipCredit?.BuildingPrice == null || this.modelProductHomeOwnershipCredit?.BuildingPrice === undefined || this.modelProductHomeOwnershipCredit?.BuildingPrice <= 0)
		{
			modelResponse.MessageContent = "Plafond tidak boleh kosong! Silahkan isi!";
		}
		else if (this.PolicyStartDate == null || this.PolicyStartDate === undefined || this.PolicyStartDate.toString() === "" )
		{
			modelResponse.MessageContent = "Tanggal mulai penutupan tidak boleh kosong! Silahkan isi!";
		}
		else if (this.modelProductHomeOwnershipCredit.OccupationRemarks == null || this.modelProductHomeOwnershipCredit.OccupationRemarks === undefined)
		{
			modelResponse.MessageContent = "Tipe okupasi tidak boleh kosong! Silahkan isi!";
		}
		else if (this.modelProductHomeOwnershipCredit?.RiskLocationRemarks == null || this.modelProductHomeOwnershipCredit?.RiskLocationRemarks === undefined || this.modelProductHomeOwnershipCredit?.RiskLocationRemarks === "")
		{
			modelResponse.MessageContent = "Alamat tidak boleh kosong! Silahkan isi!";
		}
		else if (this.PolicyholderPostalCode == null || this.PolicyholderPostalCode === undefined || this.PolicyholderPostalCode === "")
		{
			modelResponse.MessageContent = "Kode pos tidak boleh kosong! Silahkan isi!";
		}
		else if (this.PolicyholderPostalCode.length < 4 || this.PolicyholderPostalCode.length > 15)
		{
			modelResponse.MessageContent = "Panjang karakter 'Kode Pos' tidak boleh dibawah 4 atau diatas 15 digit!";
		}
		else if (this.modelProductHomeOwnershipCredit == null || this.modelProductHomeOwnershipCredit === undefined)
		{
			modelResponse.MessageContent = "Terjadi kesalahan pada model karena kosong, silahkan kontak pengembang!";
		}
		else
		{
			modelResponse.MessageContent = "Product general is filled correctly";
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
		}

		return modelResponse;
	}

	validateExcelForListenUpload(arrayExcel: [][])
	{
		const modelResponse: ResponseModel = new ResponseModel();
		let modelValidate: ValidateModel = new ValidateModel();
		let modelPostal: PostalModel = new PostalModel();
		const arrayPostalModel: Array<PostalModel> = [];
		const arrayValidateModel: Array<ValidateModel> = [];
		const arrayProductGeneralModelRequest: Array<ProductGeneralModel> = [];
		let modelProductGeneralTemp: ProductGeneralModel;
		modelResponse.setForValidation("Unggahan lampiran Data Penutupan");

		let stringNumberData: string | number;
		let booleanValidation: boolean;
		let stringLoanAccountNumber: string = "";
		let stringOccupationRemarks: string = "";

		for (let numberIndexRow = 1; numberIndexRow < arrayExcel.length; numberIndexRow++)
		{
			booleanValidation = true;
			modelProductGeneralTemp = new ProductGeneralModel();
			modelProductGeneralTemp.modelProductHomeOwnershipCredit = new ProductHomeOwnershipCreditModel();
			modelProductGeneralTemp.clearProductGeneralForProductHomeOwnershipCredit();

			/* LOAN ACCOUNT NUMBER - START */

			stringLoanAccountNumber = "" + arrayExcel[numberIndexRow][NumberConstant.NUMBER_EXCEL_COLUMN_LOANACCOUNTNUMBER];

			if (stringLoanAccountNumber != null || stringLoanAccountNumber !== undefined || stringLoanAccountNumber !== "")
			{
				if (stringLoanAccountNumber.search(/^[-+]?[0-9]+$/))
				{
					modelValidate = new ValidateModel();
					modelValidate.RowMatrix = numberIndexRow + 1;
					modelValidate.ColumnMatrix = 1;
					modelValidate.MessageContent = "Kolom 'Nomor Rekening Pinjaman' tidak menggunakan format angka!";
					arrayValidateModel.push(modelValidate);
				}
				else
				{
					modelProductGeneralTemp.modelProductHomeOwnershipCredit.LoanAccountNumber = stringLoanAccountNumber;

					for (let numberIndexColumn = numberIndexRow+1; numberIndexColumn < arrayExcel.length; numberIndexColumn++)
					{
						if (arrayExcel[numberIndexRow][NumberConstant.NUMBER_EXCEL_COLUMN_LOANACCOUNTNUMBER] === arrayExcel[numberIndexColumn][NumberConstant.NUMBER_EXCEL_COLUMN_LOANACCOUNTNUMBER])
						{
							modelValidate = new ValidateModel();
							modelValidate.RowMatrix = numberIndexRow + 1;
							modelValidate.ColumnMatrix = 8;
							modelValidate.MessageContent = "Ditemukan nomor rekening pinjaman yang sama, " + arrayExcel[numberIndexRow][NumberConstant.NUMBER_EXCEL_COLUMN_LOANACCOUNTNUMBER] + ", pada baris " + (numberIndexRow + 1) + " dan " + (numberIndexColumn + 1);
							arrayValidateModel.push(modelValidate);
						}
						else
						{
						}
					}
				}
			}
			else
			{
				modelValidate = new ValidateModel();
				modelValidate.RowMatrix = numberIndexRow + 1;
				modelValidate.ColumnMatrix = 1;
				modelValidate.MessageContent = "Kolom 'Nomor Rekening Pinjaman' tidak boleh kosong!";
				arrayValidateModel.push(modelValidate);
			}

			/* LOAN ACCOUNT NUMBER - END */

			/* INSURED NAME - START */

			modelProductGeneralTemp.modelProductHomeOwnershipCredit.InsuredName = arrayExcel[numberIndexRow][NumberConstant.NUMBER_EXCEL_COLUMN_INSUREDNAME];

			if (modelProductGeneralTemp.modelProductHomeOwnershipCredit.InsuredName == null || modelProductGeneralTemp.modelProductHomeOwnershipCredit.InsuredName === undefined || modelProductGeneralTemp.modelProductHomeOwnershipCredit.InsuredName === "")
			{
				modelValidate = new ValidateModel();
				modelValidate.RowMatrix = numberIndexRow + 1;
				modelValidate.ColumnMatrix = 2;
				modelValidate.MessageContent = "Kolom 'Nama Debitur' tidak boleh kosong!";
				arrayValidateModel.push(modelValidate);
			}
			else
			{
			}

			/* INSURED NAME - END */

			/* BUILDING PRICE - START */

			modelProductGeneralTemp.modelProductHomeOwnershipCredit.BuildingPrice = arrayExcel[numberIndexRow][NumberConstant.NUMBER_EXCEL_COLUMN_BUILDINGPRICE];

			if (modelProductGeneralTemp.modelProductHomeOwnershipCredit.BuildingPrice == null || modelProductGeneralTemp.modelProductHomeOwnershipCredit.BuildingPrice === undefined)
			{
				modelValidate = new ValidateModel();
				modelValidate.RowMatrix = numberIndexRow + 1;
				modelValidate.ColumnMatrix = 3;
				modelValidate.MessageContent = "Kolom 'Plafond' tidak boleh kosong!";
				arrayValidateModel.push(modelValidate);
			}
			else if ( typeof(modelProductGeneralTemp.modelProductHomeOwnershipCredit.BuildingPrice) !== "number" )
			{
				modelValidate = new ValidateModel();
				modelValidate.RowMatrix = numberIndexRow + 1;
				modelValidate.ColumnMatrix = 3;
				modelValidate.MessageContent = "Kolom 'Plafond' tidak menggunakan format angka!";
				arrayValidateModel.push(modelValidate);
			}
			else if (modelProductGeneralTemp.modelProductHomeOwnershipCredit.BuildingPrice <= 0)
			{
				modelValidate = new ValidateModel();
				modelValidate.RowMatrix = numberIndexRow + 1;
				modelValidate.ColumnMatrix = 3;
				modelValidate.MessageContent = "'Plafond' dibawah atau sama dengan 0!";
				arrayValidateModel.push(modelValidate);
			}
			else
			{
			}

			/* BUILDING PRICE - END */

			/* POLICY START DATE - START */

			stringNumberData = arrayExcel[numberIndexRow][NumberConstant.NUMBER_EXCEL_COLUMN_POLICYHOLDERSTARTDATE];

			if (stringNumberData != null || stringNumberData !== undefined || stringNumberData !== "")
			{
				if (typeof(stringNumberData) === "string")
				{
					modelProductGeneralTemp.PolicyStartDate = this.convertStringDateToDate(stringNumberData as string);
				}
				else if (typeof(stringNumberData) === "number")
				{
					modelProductGeneralTemp.PolicyStartDate = this.convertExcelDateToDate(stringNumberData as number);
				}
				else
				{
					booleanValidation = false;
				}

				if (booleanValidation)
				{
					if (modelProductGeneralTemp.PolicyStartDate == null || modelProductGeneralTemp.PolicyStartDate === undefined)
					{
						modelValidate = new ValidateModel();
						modelValidate.RowMatrix = numberIndexRow + 1;
						modelValidate.ColumnMatrix = 4;
						modelValidate.MessageContent = "Kolom 'Tanggal Mulai Penutupan' tidak boleh kosong!";
						arrayValidateModel.push(modelValidate);
						booleanValidation = false;
					}
					else if (modelProductGeneralTemp.PolicyStartDate.toString() === "Invalid Date")
					{
						modelValidate = new ValidateModel();
						modelValidate.RowMatrix = numberIndexRow + 1;
						modelValidate.ColumnMatrix = 4;
						modelValidate.MessageContent = "Kolom 'Tanggal Mulai Penutupan' memiliki format yang salah!";
						arrayValidateModel.push(modelValidate);
						booleanValidation = false;
					}
					else if (modelProductGeneralTemp.PolicyStartDate.getFullYear() > new Date().getFullYear())
					{
						modelValidate = new ValidateModel();
						modelValidate.RowMatrix = numberIndexRow + 1;
						modelValidate.ColumnMatrix = 4;
						modelValidate.MessageContent = "Kolom 'Tanggal Mulai Penutupan' memiliki tahun lebih dari tahun ini! Gunakan HANYA tahun ini atau tahun yang sudah berlalu.";
						arrayValidateModel.push(modelValidate);
						booleanValidation = false;
					}
					else
					{

					}
				}
				else
				{
				}
			}
			else
			{
				modelValidate = new ValidateModel();
				modelValidate.RowMatrix = numberIndexRow + 1;
				modelValidate.ColumnMatrix = 4;
				modelValidate.MessageContent = "Kolom 'Tanggal Mulai Penutupan' tidak boleh kosong!";
				arrayValidateModel.push(modelValidate);
			}

			/* POILCY START DATE - END */

			/* OCCUPATION REMARKS - START */

			stringOccupationRemarks = "" + arrayExcel[numberIndexRow][NumberConstant.NUMBER_EXCEL_COLUMN_OCCUPATIONREMARKS];

			if (stringOccupationRemarks == null || stringOccupationRemarks === undefined || stringOccupationRemarks === "")
			{
				modelValidate = new ValidateModel();
				modelValidate.RowMatrix = numberIndexRow + 1;
				modelValidate.ColumnMatrix = 5;
				modelValidate.MessageContent = "Kolom 'Okupasi' tidak boleh kosong!";
				arrayValidateModel.push(modelValidate);
			}
			else if (stringOccupationRemarks.toLowerCase() === "rumah susun")
			{
				modelProductGeneralTemp.modelProductHomeOwnershipCredit.OccupationRemarks = ENUM_OCCUPATIONREMARKS_TYPE.FlatHouse;
			}
			else if(stringOccupationRemarks.toLowerCase() === "rumah tinggal")
			{
				modelProductGeneralTemp.modelProductHomeOwnershipCredit.OccupationRemarks = ENUM_OCCUPATIONREMARKS_TYPE.LandedHouse;
			}
			else
			{
				modelValidate = new ValidateModel();
				modelValidate.RowMatrix = numberIndexRow + 1;
				modelValidate.ColumnMatrix = 5;
				modelValidate.MessageContent = "Kolom 'Okupasi' memiliki format yang salah! Silahkan gunakan Rumah Susun/Rumah Tinggal!";
				arrayValidateModel.push(modelValidate);
			}

			/* OCCUPATION REMARKS - END */

			/* RISK LOCATION RISK REMARKS - START */

			modelProductGeneralTemp.modelProductHomeOwnershipCredit.RiskLocationRemarks = arrayExcel[numberIndexRow][NumberConstant.NUMBER_EXCEL_COLUMN_RISKLOCATIONREMARKS];

			if (modelProductGeneralTemp.modelProductHomeOwnershipCredit.RiskLocationRemarks == null || modelProductGeneralTemp.modelProductHomeOwnershipCredit.RiskLocationRemarks === undefined || modelProductGeneralTemp.modelProductHomeOwnershipCredit.RiskLocationRemarks === "")
			{
				modelValidate = new ValidateModel();
				modelValidate.RowMatrix = numberIndexRow + 1;
				modelValidate.ColumnMatrix = 4;
				modelValidate.MessageContent = "Kolom 'Alamat' tidak boleh kosong!";
				arrayValidateModel.push(modelValidate);
			}
			else
			{
			}

			/* RISK LOCATION RISK REMARKS - END */

			/* POLICY HOLDER POSTAL CODE - START */

			modelProductGeneralTemp.PolicyholderPostalCode = arrayExcel[numberIndexRow][NumberConstant.NUMBER_EXCEL_COLUMN_POLICYHOLDERPOSTALCODE] as string;

			if (modelProductGeneralTemp.PolicyholderPostalCode == null || modelProductGeneralTemp.PolicyholderPostalCode === undefined || modelProductGeneralTemp.PolicyholderPostalCode === "")
			{
				modelValidate = new ValidateModel();
				modelValidate.RowMatrix = numberIndexRow + 1;
				modelValidate.ColumnMatrix = 5;
				modelValidate.MessageContent = "Kolom 'Kode Pos' tidak boleh kosong!";
				arrayValidateModel.push(modelValidate);
			}
			else if (modelProductGeneralTemp.PolicyholderPostalCode.toString().length < 4 || modelProductGeneralTemp.PolicyholderPostalCode.toString().length > 15)
			{
				modelValidate = new ValidateModel();
				modelValidate.RowMatrix = numberIndexRow + 1;
				modelValidate.ColumnMatrix = 5;
				modelValidate.MessageContent = "Panjang karakter 'Kode Pos' dibawah 4 atau diatas 15 digit!";
				arrayValidateModel.push(modelValidate);
			}
			else
			{
				modelPostal = new PostalModel();
				modelPostal.Code = modelProductGeneralTemp.PolicyholderPostalCode;
				arrayPostalModel.push(modelPostal);
			}

			/* POLICY HOLDER POSTAL CODE - END */

			if (arrayValidateModel.length > 0)
			{
				modelResponse.MessageContent = "Terjadi kesalahan pada data penutupan yang diunggah! Silahkan lihat tabel untuk daftar kesalahan.";
				modelResponse.State = ENUM_RESPONSE_STATE.Fail;
			}
			else
			{
				arrayProductGeneralModelRequest.push(modelProductGeneralTemp);

				modelResponse.State = ENUM_RESPONSE_STATE.Success;
				modelResponse.MessageContent = "Form is filled correctly.";
			}
		}

		return [ modelResponse, arrayValidateModel, arrayPostalModel, arrayProductGeneralModelRequest ];
	}

	validateProductForExport(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.setForValidation("Product General");

		if (this.PolicyStartDate == null || this.PolicyStartDate === undefined || this.PolicyEndDate == null || this.PolicyEndDate === undefined)
		{
			modelResponse.MessageContent = "Product general is filled correctly";
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
		}
		else
		{
			if (this.PolicyStartDate > this.PolicyEndDate)
			{
				modelResponse.MessageContent = "Periode Awal tidak boleh lebih besar daripada Periode Akhir! Silahkan isi!";
			}
			else if (this.PolicyEndDate === this.PolicyStartDate)
			{
				modelResponse.MessageContent = "Periode Awal dan Periode Akhir tidak boleh sama! Silahkan isi!";
			}
			else if (this.PolicyEndDate.calculateDayDifference(this.PolicyStartDate) > 31)
			{
				modelResponse.MessageContent = "Periode tidak boleh lebih dari 31 hari";
			}
			else
			{
				modelResponse.MessageContent = "Product general is filled correctly";
				modelResponse.State = ENUM_RESPONSE_STATE.Success;
			}
		}

		return modelResponse;
	}

	//#endregion


	//#region CLEAR

	clearProductGeneralForProductHomeOwnershipCredit(): void
	{
		this.modelProductAsri = undefined;
		this.modelProductAsriSyariah = undefined;
		this.modelProductOto = undefined;
		this.modelProductOtoSyariah = undefined;
		this.modelProductPersonalAccident = undefined;
		this.modelProductPersonalAccidentSyariah = undefined;
	}

	//#endregion


	//#region GETTER

	getStatus(): string
	{
		if (this.Status == null || this.Status === undefined)
		{
			return "Belum Disetujui";
		}
		else if (this.Status === ENUM_SPPA_STATUS.RejectedSurvey)
		{
			return "Ditolak";
		}
		else if (this.Status === ENUM_SPPA_STATUS.Approved)
		{
			return "Disetujui";
		}
		else if (this.Status === ENUM_SPPA_STATUS.OnProgress)
		{
			return "Belum Disetujui";
		}
		else
		{
			return ENUM_SPPA_STATUS[this.Status];
		}
	}

	//#endregion


	//#region FUNCTION

	convertStringDateToDate(stringValue: any): Date
	{
		const arrayDateValue: [] = stringValue.split("/");

		return new Date(arrayDateValue[NumberConstant.NUMBER_EXCEL_COLUMN_POLICYHOLDERSTARTDATE+2], arrayDateValue[NumberConstant.NUMBER_EXCEL_COLUMN_POLICYHOLDERSTARTDATE+1]-1, arrayDateValue[NumberConstant.NUMBER_EXCEL_COLUMN_POLICYHOLDERSTARTDATE]);
	}

	convertExcelDateToDate(serial)
	{
		return new Date(-2209075200000 + (serial - (serial < 61 ? 0 : 1)) * 86400000);
	}

	//#endregion
}

//#endregion