/* eslint-disable no-unused-vars */
//#region IMPORT

import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { QuicklinkModule, QuicklinkStrategy } from "ngx-quicklink";
import { AboutComponent } from "./components/about/about.component";
import { HomeComponent } from "./components/home/home.component";
import { SigninComponent } from "./components/signin/signin.component";
import { DashboardComponent } from "./components/dashboard/dashboard.component";
import { AuthorizedGuardService } from "./services/guards/authorized.guard.service";
import { PublicGuardService } from "./services/guards/public.guard.service";
import { StringConstant } from "./constants/string.constant";
import { ReportlistComponent } from "./components/report/reportlist/reportlist.component";
import { ProductlistComponent } from "./components/product/productlist/productlist.component";
import { ProductdetailComponent } from "./components/product/productdetail/productdetail.component";

//#endregion


//#region DECLARATION

const arrayRoute: Routes =
[
	{
		path: "",
		redirectTo: "/",
		pathMatch: "full"
	},
	{
		path: "",
		component: SigninComponent,
		canActivate: [PublicGuardService]
	},
	{
		path: "signin",
		component: SigninComponent,
		canActivate: [PublicGuardService]
	},
	{
		path: "home",
		redirectTo: "home/dashboard",
	},
	{
		path: "home",
		component: HomeComponent,
		canActivate: [AuthorizedGuardService],
		children:
		[
			{
				path: "dashboard",
				component: DashboardComponent,
			},
			{
				path: "product",
				loadChildren: () => import("./components/product/product.module").then(pageModule => pageModule.ProductModule)
			},
			{
				path: "export",
				children:
				[
					{
						path: "list",
						component: ReportlistComponent,
					}
				]
			},
			{
				path: "about",
				component: AboutComponent,
			}
		]
	},
];

//#endregion


//#region MODULE

@NgModule
(
	{
		imports:
		[
			QuicklinkModule,
			RouterModule.forRoot(arrayRoute,
				{
					preloadingStrategy: QuicklinkStrategy
				})
		],
		exports: [RouterModule]
	}
)


//#endregion


//#region CLASS

export class AppRoutingModule { }

//#endregion
