//#region IMPORT

import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { WebAddressConstant } from "../constants/webaddress.constant";
import { GeneralServiceInterface } from "../interfaces/general.service.interface";
import { BRIModel } from "../models/bri.model";
import { UserModel } from "../models/user.model";
import { BaseService } from "./bases/base.service";
import { SessionService } from "./session.service";

//#endregion


//#region INJECTABLE

@Injectable
(
	{
		providedIn: "root"
	}
)

//#endregion


//#region  CLASS

export class BRIService extends BaseService
{

	//#region CONSTRUCTOR

	constructor(clientHTTP: HttpClient, serviceSession: SessionService)
	{
		super(clientHTTP, serviceSession);
	}

	//#endregion


	//#region SELECT

	selectBRIByEmail(interfaceGeneralService: GeneralServiceInterface, modelUser: UserModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelUser), WebAddressConstant.STRING_URL_BRI_SELECTBRIBYEMAIL);
	}

	//#endregion


	//#region VERIFY and SIGNOUT

	verifyBRIByEmail(interfaceGeneralService: GeneralServiceInterface, modelUser: UserModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelUser), WebAddressConstant.STRING_URL_BRI_VERIFYBRIBYEMAIL);
	}

	signOut(interfaceGeneralService: GeneralServiceInterface, modelBRI: BRIModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelBRI), WebAddressConstant.STRING_URL_BRI_SIGNOUT);
	}

	//#endregion
}

//#endregion