//#region IMPORT

import { environment as environmentGeneral } from "./environment.general";

//#endregion


//#region ENVIRONMENT

export const environment =
{
	...environmentGeneral,


	//#region CONFIGURATION

	production: false,
	enableDebug: true,

	//#endregion


	//#region BUILD

	BUILD: "Development",

	//#endregion


	//#region API

	URL_USER_API: environmentGeneral.SCHEMA_HTTPSECURE + environmentGeneral.HOST_USER_API_DEVELOPMENT + environmentGeneral.CHARACTER_SEPARATOR_PORT + environmentGeneral.PORT_DEVELOPMENT_PUBLIC,
	URL_PRODUCT_API: environmentGeneral.SCHEMA_HTTPSECURE + environmentGeneral.HOST_PRODUCT_API_DEVELOPMENT + environmentGeneral.CHARACTER_SEPARATOR_PORT + environmentGeneral.PORT_DEVELOPMENT_PUBLIC,
	URL_POLICY_API: environmentGeneral.SCHEMA_HTTPSECURE + environmentGeneral.HOST_POLICY_API_DEVELOPMENT + environmentGeneral.CHARACTER_SEPARATOR_PORT + environmentGeneral.PORT_DEVELOPMENT_PUBLIC,
	URL_BROADCAST_API: environmentGeneral.SCHEMA_HTTPSECURE + environmentGeneral.HOST_BROADCAST_API_DEVELOPMENT + environmentGeneral.CHARACTER_SEPARATOR_PORT + environmentGeneral.PORT_BROADCAST_PUBLIC,
	URL_INTEGRATION_API: environmentGeneral.SCHEMA_HTTPSECURE + environmentGeneral.HOST_INTEGRATION_API_DEVELOPMENT + environmentGeneral.CHARACTER_SEPARATOR_PORT + environmentGeneral.PORT_INTEGRATION_PUBLIC,

	//#endregion
};

//#endregion