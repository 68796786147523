/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/consistent-type-assertions */
/* eslint-disable @typescript-eslint/member-ordering */
// file deepcode ignore OverwriteAssignment: domain reused to other
//#region IMPORT

import { BreakpointObserver } from "@angular/cdk/layout";
import { Component, Injectable } from "@angular/core";
import { DateAdapter, MatDateFormats, MAT_DATE_FORMATS, NativeDateAdapter } from "@angular/material/core";
import { Router } from "@angular/router";
import { ResponseCodeConstant } from "src/app/constants/responsecode.constant";
import { DashboardBaseModel } from "src/app/models/bases/dashboard.base.model";
import { DashboardProductHomeOwnershipCreditSummaryModel } from "src/app/models/dashboardproducthomeownershipsummary.model";
import { ResponseModel } from "src/app/models/response.model";
import { DashboardService } from "src/app/services/dashboard.service";
import { SessionService } from "src/app/services/session.service";
import { BaseAuthourizeChartComponent } from "../bases/baseauthourizechart.component";

//#endregion


//#region CONST

export const appDateFormats: MatDateFormats =
{
	parse:
	{
		dateInput: { month: "short", year: "numeric", day: "numeric" },
	},
	display:
	{
		dateInput: "input",
		monthYearLabel: { year: "numeric", month: "numeric" },
		dateA11yLabel: { year: "numeric", month: "long", day: "numeric"
		},
		monthYearA11yLabel: { year: "numeric", month: "long" },
	}
};

//#endregion


//#region CLASS DECLARATION

@Injectable() export class AppDateAdapter extends NativeDateAdapter
{
	format(date: Date, displayFormat: Object): string
	{
		if (displayFormat === "input")
		{
			let stringDay: string = date.getUTCDate().toString();
			stringDay = +stringDay < 10 ? "0" + stringDay : stringDay;
			let stringMonth: string = (date.getUTCMonth() + 1).toString();
			stringMonth = +stringMonth < 10 ? "0" + stringMonth : stringMonth;
			const numberYear = date.getFullYear();
			return `${stringDay}/${stringMonth}/${numberYear}`;
		}
		return date.toDateString();
	}
}

//#endregion


//#region COMPONENT

@Component
(
	{
		selector: "app-dashboard",
		templateUrl: "./dashboard.component.html",
		styleUrls: ["./dashboard.component.sass"],
		providers:
		[
			{provide: DateAdapter, useClass: AppDateAdapter},
			{provide: MAT_DATE_FORMATS, useValue: appDateFormats}
		]
	}
)

//#endregion


//#region CLASS

export class DashboardComponent extends BaseAuthourizeChartComponent
{
	//#region DECLARATION

	public _modelDashboardProductHomeOwnershipCreditSummary: DashboardProductHomeOwnershipCreditSummaryModel;
	public _modelDashboardBase: DashboardBaseModel;

	//#region CONSTRUCTOR

	constructor(private _serviceDashboard: DashboardService, serviceSession: SessionService, router: Router, breakpointObserver: BreakpointObserver)
	{
		super(serviceSession, router, breakpointObserver);
		this._modelDashboardProductHomeOwnershipCreditSummary = new DashboardProductHomeOwnershipCreditSummaryModel();

		this._modelDashboardBase = new DashboardBaseModel();
		this.setStartDate(this._modelDashboardBase.StartDate);
		this.setEndDate(this._modelDashboardBase.EndDate);
	}

	//#endregion


	//#region NAVIGATION

	ngOnInit(): void
	{
		this.callSelectDashboardProductFireLongTermSyariah(this, () => {});
	}

	//#endregion


	//#region WEB SERVICE

	private callSelectDashboardProductFireLongTermSyariah(componentCurrent: DashboardComponent, success: () => void): void
	{
		this._functionUserInterface.setLoadingProgress(1);
		this._modelDashboardProductHomeOwnershipCreditSummary.clearChartForRequest();
		componentCurrent._modelDashboardProductHomeOwnershipCreditSummary.clearChartResult();
		this._serviceDashboard.selectDashboardProductHomeOwnershipCredit
		({
			success(modelResponse: ResponseModel): void
			{
				if (modelResponse.ServiceResponseCode === ResponseCodeConstant.STRING_RESPONSECODE_SERVICE_SUCCESS)
				{
					if (modelResponse.Data !== undefined)
					{
						componentCurrent._modelDashboardProductHomeOwnershipCreditSummary.setModelFromString(modelResponse.Data);

						componentCurrent._modelDashboardProductHomeOwnershipCreditSummary.listModelChartItemStatusOnProgress = componentCurrent.convertChartItemServerToChartItemSingleLibrary(componentCurrent._modelDashboardProductHomeOwnershipCreditSummary.listModelChartItemStatusOnProgress);
						componentCurrent._modelDashboardProductHomeOwnershipCreditSummary.modelColorStatusOnProgress.domain = componentCurrent.generateChartColor(componentCurrent._modelDashboardProductHomeOwnershipCreditSummary.listModelChartItemStatusOnProgress);

						componentCurrent._modelDashboardProductHomeOwnershipCreditSummary.listModelChartItemStatusApproved = componentCurrent.convertChartItemServerToChartItemSingleLibrary(componentCurrent._modelDashboardProductHomeOwnershipCreditSummary.listModelChartItemStatusApproved);
						componentCurrent._modelDashboardProductHomeOwnershipCreditSummary.modelColorStatusApproved.domain = componentCurrent.generateChartColor(componentCurrent._modelDashboardProductHomeOwnershipCreditSummary.listModelChartItemStatusApproved);

						componentCurrent._modelDashboardProductHomeOwnershipCreditSummary.listModelChartItemStatusRejected = componentCurrent.convertChartItemServerToChartItemSingleLibrary(componentCurrent._modelDashboardProductHomeOwnershipCreditSummary.listModelChartItemStatusRejected);
						componentCurrent._modelDashboardProductHomeOwnershipCreditSummary.modelColorStatusRejected.domain = componentCurrent.generateChartColor(componentCurrent._modelDashboardProductHomeOwnershipCreditSummary.listModelChartItemStatusRejected);

						componentCurrent._modelDashboardProductHomeOwnershipCreditSummary.listModelChartItemBuildingPrice = componentCurrent.convertChartItemServerToChartItemSingleLibrary(componentCurrent._modelDashboardProductHomeOwnershipCreditSummary.listModelChartItemBuildingPrice);
						componentCurrent._modelDashboardProductHomeOwnershipCreditSummary.modelColorBuildingPrice.domain = componentCurrent.generateChartColor(componentCurrent._modelDashboardProductHomeOwnershipCreditSummary.listModelChartItemBuildingPrice);

						componentCurrent._functionUserInterface.updateLoadingProgress();

						success();
					}
					else
					{
						componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
					}
				}
				else
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
				}
			},
			fail(modelResponse: ResponseModel): void
			{
				componentCurrent._functionUserInterface.showDialogFromModelResponseWithRetry(modelResponse, () => { componentCurrent.callSelectDashboardProductFireLongTermSyariah(componentCurrent, () => { success(); }); });
			},
			signOut(modelResponse: ResponseModel): void
			{
				componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent.signOut(); });
			}
		}, this._modelDashboardProductHomeOwnershipCreditSummary);
	}

	//#endregion


	//#region FUNCTION

	//#endregion


	//#region SETTER

	private setStartDate(dateStart?: Date): void
	{
		if (dateStart == null || dateStart === undefined)
		{
			this._modelDashboardProductHomeOwnershipCreditSummary.StartDate = undefined;
		}
		else
		{
			this._modelDashboardProductHomeOwnershipCreditSummary.StartDate = dateStart;
		}
	}

	private setEndDate(dateEnd?: Date): void
	{
		if (dateEnd == null || dateEnd === undefined)
		{
			this._modelDashboardProductHomeOwnershipCreditSummary.EndDate = undefined;
		}
		else
		{
			this._modelDashboardProductHomeOwnershipCreditSummary.EndDate = dateEnd;
		}
	}

	//#endregion


	//#region GETTER

	getEndDate(event): void
	{
		this._modelDashboardBase.EndDate = event.value.clearUTC();

		this.setEndDate(this._modelDashboardBase.EndDate);

		if
		(
			this._modelDashboardProductHomeOwnershipCreditSummary.StartDate == null ||
			this._modelDashboardProductHomeOwnershipCreditSummary.StartDate === undefined ||
			this._modelDashboardProductHomeOwnershipCreditSummary.EndDate == null ||
			this._modelDashboardProductHomeOwnershipCreditSummary.EndDate === undefined
		)
		{

		}
		else
		{
			this.callSelectDashboardProductFireLongTermSyariah(this, () => {});
		}
	};

	getStartDate(event): void
	{
		this._modelDashboardBase.StartDate = event.value.clearUTC();
		this.setStartDate(this._modelDashboardBase.StartDate);
	};

	//#endregion
}

//#endregion