//#region IMPORT

import { NgModule } from "@angular/core";
import { ConvertToBroadcastURL, ConvertToProductURL } from "./hyperlink.pipe";
import { ConvertByteForPreview, ConvertEmptyToDash, ConvertExtensionToIcon, ConvertNoDivisionToCustomer, ConvertSeparateEnumSpace, ConvertToDateMedium, ConvertToDateShort, ConvertToDateView, CurrencyValue, InitiateWithoutUTC } from "./converter.pipe";
import { TrimShort } from "./trim.pipe";

//#endregion


//#region MODULE

@NgModule
(
	{
		declarations:
		[
			ConvertToProductURL,
			ConvertToDateMedium,
			ConvertToDateShort,
			ConvertEmptyToDash,
			InitiateWithoutUTC,
			CurrencyValue,
			ConvertSeparateEnumSpace,
			ConvertNoDivisionToCustomer,
			TrimShort,
			ConvertByteForPreview,
			ConvertExtensionToIcon,
			ConvertToBroadcastURL,
			ConvertToDateView
		],
		exports:
		[
			ConvertToProductURL,
			ConvertToDateMedium,
			ConvertToDateShort,
			ConvertEmptyToDash,
			InitiateWithoutUTC,
			CurrencyValue,
			ConvertSeparateEnumSpace,
			ConvertNoDivisionToCustomer,
			TrimShort,
			ConvertByteForPreview,
			ConvertExtensionToIcon,
			ConvertToBroadcastURL,
			ConvertToDateView
		]
	}
)

//#endregion


//#region CLASS

export class PipeModule { }

//#endregion