//#region CLASS

export class NumberConstant
{
	//#region USER INTERFACE

	static NUMBER_PRODUCTASRI_GALLERY_SIZE: number = 5;
	static NUMBER_PRODUCTOTO_GALLERY_SIZE: number = 9;

	static NUMBER_VALUE_THEME_SECONDARY_DARK_RED: number = 242;
	static NUMBER_VALUE_THEME_SECONDARY_DARK_GREEN: number = 111;
	static NUMBER_VALUE_THEME_SECONDARY_DARK_BLUE: number = 33;

	static NUMBER_VALUE_THEME_PRIMARY_DARK_RED: number = 40;
	static NUMBER_VALUE_THEME_PRIMARY_DARK_GREEN: number = 118;
	static NUMBER_VALUE_THEME_PRIMARY_DARK_BLUE: number = 188;

	static NUMBER_VALUE_THEME_CHART_STEP_RED: number = 20;
	static NUMBER_VALUE_THEME_CHART_STEP_GREEN: number = 15;
	static NUMBER_VALUE_THEME_CHART_STEP_BLUE: number = 3;

	static NUMBER_VALUE_THEME_PRIMARY_CHART_STEP_RED: number = 5;
	static NUMBER_VALUE_THEME_PRIMARY_CHART_STEP_GREEN: number = 15;
	static NUMBER_VALUE_THEME_PRIMARY_CHART_STEP_BLUE: number = 15;

	static NUMBER_VALUE_DECIMAL_PREVIEW: number = 2;

	static NUMBER_VALUE_POSITION_0: number = 0;
	static NUMBER_VALUE_POSITION_1: number = 1;
	static NUMBER_VALUE_POSITION_2: number = 2;

	static NUMBER_EXCEL_COLUMN_LOANACCOUNTNUMBER: number = 0;
	static NUMBER_EXCEL_COLUMN_INSUREDNAME: number = 1;
	static NUMBER_EXCEL_COLUMN_BUILDINGPRICE: number = 2;
	static NUMBER_EXCEL_COLUMN_POLICYHOLDERSTARTDATE: number = 3;
	static NUMBER_EXCEL_COLUMN_OCCUPATIONREMARKS: number = 4;
	static NUMBER_EXCEL_COLUMN_RISKLOCATIONREMARKS: number = 5;
	static NUMBER_EXCEL_COLUMN_POLICYHOLDERPOSTALCODE: number = 6;

	//#endregion
}

//#endregion