//#region IMPORT

import { NgxChartsModule } from "@swimlane/ngx-charts";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatNativeDateModule } from "@angular/material/core";
import { MatInputModule } from "@angular/material/input";
import { BrowserModule } from "@angular/platform-browser";
import { APP_INITIALIZER, NgModule } from "@angular/core";
import { HttpClientModule } from "@angular/common/http";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { ServiceWorkerModule } from "@angular/service-worker";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./components/root/app.component";
import { SigninComponent } from "./components/signin/signin.component";
import { HomeComponent } from "./components/home/home.component";
import { PipeModule } from "./functions/pipes/pipe.module";
import { DashboardComponent } from "./components/dashboard/dashboard.component";
import { TableControlIncludeComponent } from "./components/includes/tablecontrol.include/tablecontrol.include.component";
import { AboutComponent } from "./components/about/about.component";
import { ReportlistComponent } from "./components/report/reportlist/reportlist.component";
import { ProductlistComponent } from "./components/product/productlist/productlist.component";
import { ProductdetailComponent } from "./components/product/productdetail/productdetail.component";
import { PwaService } from "./services/pwa.service";

//#endregion


//#region MODULE

const initializer = (pwaService: PwaService) => () => pwaService.setupInstaller();
@NgModule
(
	{
		declarations:
		[
			AppComponent,
			SigninComponent,
			HomeComponent,
			DashboardComponent,
			AboutComponent,
			TableControlIncludeComponent,
			ReportlistComponent,
			ProductlistComponent,
			ProductdetailComponent,
		],
		imports:
		[
			BrowserModule,
			AppRoutingModule,
			FormsModule,
			ReactiveFormsModule,
			HttpClientModule,
			PipeModule,
			BrowserAnimationsModule,
			NgxChartsModule,
			MatNativeDateModule,
			MatInputModule,
			MatDatepickerModule,
			ServiceWorkerModule.register("ngsw-worker.js", {
				enabled: true,
				// Register the ServiceWorker as soon as the app is stable
				// or after 30 seconds (whichever comes first).
				registrationStrategy: "registerWhenStable:30000"
			}),
		],
		providers:
		[
			{
				provide: APP_INITIALIZER, useFactory: initializer, deps: [PwaService], multi: true
			}
		],
		bootstrap: [AppComponent]
	}
)

//#endregion


//#region CLASS

export class AppModule { }

//#endregion