//#region IMPORT

import { Color, ScaleType } from "@swimlane/ngx-charts";
import { ENUM_RESPONSE_STATE } from "src/app/constants/enum.constant";
import { RuleConstant } from "src/app/constants/rule.constant";
import { ResponseModel } from "../response.model";
import { BaseModel } from "./base.model";

//#endregion


//#region CLASS

export class DashboardBaseModel extends BaseModel
{
	StartDate?: Date;
	EndDate?: Date;
	BranchCode?: string;
	UserToken?: string;

	constructor()
	{
		super();

		this.StartDate = new Date();
		this.StartDate = this.StartDate.generateDateToday();
		this.EndDate = new Date();
		this.EndDate = this.EndDate.generateDateToday();
		this.EndDate.setDate(this.EndDate.getDate() + RuleConstant.NUMBER_DASHBOARDDATEPERIOD_RANGE);
	}


	//#region VALIDATION

	validatePeriod(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.setForValidation("Dashboard");

		if (this.StartDate == null || this.StartDate === undefined)
		{
			modelResponse.MessageContent = "Start date can't be empty.";
		}
		else if (this.EndDate == null || this.EndDate === undefined)
		{
			modelResponse.MessageContent = "End date can't be empty.";
		}
		else if (this.EndDate <= this.StartDate)
		{
			modelResponse.MessageContent = "End date can't be less than Start date.";
		}
		else if (this.EndDate.calculateDayDifference(this.StartDate) >= 365 )
		{
			modelResponse.MessageContent = "Date period need to be 1 year.";
		}
		else
		{
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
			modelResponse.MessageContent = "Form is filled correctly.";
		}

		return modelResponse;
	}

	validateForDashboard(): ResponseModel
	{
		const modelResponse: ResponseModel = this.validatePeriod();

		if (modelResponse.State === ENUM_RESPONSE_STATE.Success)
		{
			if (this.BranchCode == null || this.BranchCode === undefined || this.BranchCode === "")
			{
				modelResponse.MessageContent = "Branch can't be empty.";
				return modelResponse;
			}
			else
			{
				modelResponse.State = ENUM_RESPONSE_STATE.Success;
				modelResponse.MessageContent = "Form is filled correctly.";
				return modelResponse;
			}
		}
		else
		{
			return modelResponse;
		}
	}

	//endregion


	//#region SETTER

	protected setColorDefault(): Color
	{
		return {
			name: "",
			selectable: false,
			group: ScaleType.Time,
			domain: []
		};
	}

	//#endregion
}

//#endregion