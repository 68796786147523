//#region IMPORT

import { GeneralModel } from "./bases/general.model";

//#endregion


//#region CLASS

export class ValidateModel extends GeneralModel
{
	/*
		Attribute - START
		Description : Attribute for excel validate model, used for contain general information to cover error validation on excel.
		Author: Billy Johannes.
		Created on : Thursday, 19 August 2021.		    	Updated on : Thursday, 19 August 2021.
		Created by : Billy Johannes.						Updated by : Billy Johannes.
		Version : 1.0:0.
	*/

	RowMatrix?: number;
	ColumnMatrix?: number;
	MessageContent?: string;

	/* Attribute - END */
}

//#endregion