<div id="divBodyHome">
	<nav [ngClass]="{ 'NavShow' : _booleanNavigation, 'NavHide' : !_booleanNavigation, 'NavNone' : _booleanNavigation === null }">
		<figure>
			<div>
				<img *ngIf="_modelBRISignIn.PhotoURL" id="imageProfilePicture">
				<span *ngIf="!_modelBRISignIn.PhotoURL" id="spanProfileNameInitial">{{ _modelUserSignIn.getNameInitial() }}</span>
			</div>

			<figcaption>
				<span id="spanProfileName">{{ _modelUserSignIn.getName() }}</span>
				<span id="spanProfilePosition">{{ _modelBRISignIn.getPositionName() }}</span>
			</figcaption>

			<a class="Underline" (click)="callSignOut();">Keluar</a>
		</figure>

		<ul class="ListNavigation">
			<li class="ListItemNavigation">
				<a (click)="goToDashboard();" >Dasbor</a>
			</li>
			<li class="ListItemNavigation">
				<a (click)="goToProduct();" >Data Penutupan</a>
			</li>
			<li class="ListItemNavigation">
				<a (click)="goToExportData();" >Ekspor Data</a>
			</li>
			<li class="ListItemNavigation">
				<a (click)="goToAbout();">Tentang</a>
			</li>
		</ul>

		<div id="divNotificationInstallList" *ngIf="!this._servicePWA._booleanInstalled">
			<div>
				<span>KPRS BRI</span>
				<p class="ParagraphNotificationInstallOthers" *ngIf="!this._servicePWA._booleanIOS">Hindari kerumitan untuk kembali ke aplikasi ini dan memberi Anda pengalaman yang lebih baik!</p>
				<p class="ParagraphNotificationInstallIOS" *ngIf="this._servicePWA._booleanIOS">Untuk menginstal aplikasi web ini di perangkat Anda, ketuk tombol 'Bagikan' dan kemudian tombol 'Tambahkan ke Layar Utama'.</p>
			</div>

			<input type="button" value="Install" id="buttonNotificationInstallList" *ngIf="!this._servicePWA._booleanIOS" (click)="this._servicePWA.installWebsite();">
		</div>
	</nav>

	<main [ngClass]="{ 'MainHide' : _booleanNavigation, 'MainShow' : !_booleanNavigation }">
		<router-outlet></router-outlet>
	</main>

	<input type="button" (click)="toggleNavigation();" id="buttonNavigation">

	<img id="imageLogoHeader" src="../../assets/logos/sakina/logo_briinsurance_header.svg" alt="BRI Insurance - Logo Header">
</div>