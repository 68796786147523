//#region IMPORT

import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { ENUM_RESPONSE_STATE } from "src/app/constants/enum.constant";
import { ResponseCodeConstant } from "src/app/constants/responsecode.constant";
import { StringConstant } from "src/app/constants/string.constant";
import { CryptographyFunction } from "src/app/functions/cryptography.function";
import { BRIModel } from "src/app/models/bri.model";
import { HandshakeModel } from "src/app/models/handshake.model";
import { ResponseModel } from "src/app/models/response.model";
import { UserModel } from "src/app/models/user.model";
import { UserSessionModel } from "src/app/models/usersession.model";
import { BRIService } from "src/app/services/bri.service";
import { SessionService } from "src/app/services/session.service";
import { BasePublicComponent } from "../bases/basepublic.component";

//#endregion



//#region COMPONENT

@Component
(
	{
		selector: "app-signin",
		templateUrl: "./signin.component.html",
		styleUrls: ["./signin.component.sass"]
	}
)

//#endregion



//#region CLASS

export class SigninComponent extends BasePublicComponent implements OnInit
{
	//#region DECLARATION

	public _modelUser: UserModel;
	public _booleanFormSignInEmail: boolean;
	public _arrayNumberEmailToken: Array<number | null> = [null, null, null, null, null , null];
	public _stringVersion = StringConstant.STRING_VALUE_APPLICATION_VERSION;
	private _arrayElementInputEmailToken: NodeListOf<HTMLElement> | null = null;
	private _functionCryptography: CryptographyFunction;

	//#endregion


	//#region CONSTRUCTOR

	constructor(private _serviceBRI: BRIService, serviceSession: SessionService, router: Router)
	{
		super(serviceSession, router);

		this._modelUser = new UserModel();
		this._booleanFormSignInEmail = true;
		this._functionCryptography = new CryptographyFunction();
	}

	//#endregion


	//#region INITIALIZE

	ngOnInit(): void
	{
		this._arrayElementInputEmailToken = document.querySelectorAll(".InputToken");
	}

	//#endregion


	//#region GOES TO DASHBOARD

	callDashboard(): void
	{
		this._router.navigate(["home", "Dashboard"]);
	}

	//#endregion GOES TO DASHBOARD


	//#region WEB SERVICE

	callSelectBRIByEmail(booleanResend: boolean): void
	{
		this._functionUserInterface.setLoadingProgress(1);
		const modelUser: UserModel = new UserModel();
		modelUser.Email = this._modelUser.Email;
		const modelResponseValidation: ResponseModel = modelUser.validateEmail();

		if (modelResponseValidation.State === ENUM_RESPONSE_STATE.Success)
		{
			const componentCurrent: SigninComponent = this;

			this._serviceBRI.selectBRIByEmail
			({
				success(modelResponse: ResponseModel): void
				{
					if (modelResponse.ServiceResponseCode === ResponseCodeConstant.STRING_RESPONSECODE_SERVICE_SUCCESS )
					{
						componentCurrent._functionUserInterface.updateLoadingProgress();
						componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent._booleanFormSignInEmail = booleanResend; });
					}
					else
					{
						componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
					}
				},
				fail(modelResponse: ResponseModel): void
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponseWithRetry(modelResponse, () => { componentCurrent.callSelectBRIByEmail(!booleanResend); });
				},
				signOut(modelResponse: ResponseModel): void
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent.goToSignInWithClearSession(); });
				}
			}, modelUser);
		}
		else
		{
			this._functionUserInterface.showDialogFromModelResponse(modelResponseValidation, () => { });
		}
	}

	callVerifyBRIByEmail(): void
	{
		this._functionUserInterface.setLoadingProgress(1);
		const modelUser: UserModel = new UserModel();
		modelUser.EmailToken = this.getStringEmailTokenFromForm();
		modelUser.Email = this._modelUser.Email;
		let modelResponseValidation: ResponseModel = modelUser.validateEmail();

		if (modelResponseValidation.State === ENUM_RESPONSE_STATE.Success)
		{
			modelResponseValidation = modelUser.validateEmailToken();
		}
		else
		{

		}

		if (modelResponseValidation.State === ENUM_RESPONSE_STATE.Success)
		{
			modelUser.EmailToken = this._functionCryptography.hashWithSHA256((this.getStringEmailTokenFromForm()));
			const componentCurrent: SigninComponent = this;

			this._serviceBRI.verifyBRIByEmail
			({
				success(modelResponse: ResponseModel): void
				{
					if (modelResponse.Data !== undefined)
					{
						const modelUserResponse: UserModel = new UserModel();
						modelUserResponse.setModelFromString(modelResponse.Data);

						if (modelUserResponse != null)
						{
							componentCurrent._serviceSession.setModelUserSignIn(modelUserResponse);

							if (modelUserResponse.modelBRI !== undefined)
							{
								const modelBRI: BRIModel = new BRIModel();
								modelBRI.setModelFromObject(modelUserResponse.modelBRI);
								componentCurrent._serviceSession.setModelBRISignIn(modelBRI);
							}
							else
							{
								componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent.goToSignInWithClearSession(); });
							}

							if (modelUserResponse.modelHandshake !== undefined)
							{
								const modelHandshake: HandshakeModel = new HandshakeModel();
								modelHandshake.setModelFromObject(modelUserResponse.modelHandshake);
								componentCurrent._serviceSession.setModelHandshakeSignInToLocalStorage(modelHandshake);
							}
							else
							{
								componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent.goToSignInWithClearSession(); });
							}

							const modelUserSession = new UserSessionModel();
							modelUserSession.UserToken = modelUserResponse.Token;

							componentCurrent._serviceSession.setUserSession(modelUserSession);
							componentCurrent._functionUserInterface.updateLoadingProgress();
							componentCurrent._router.navigate(["/home"]);
						}
						else
						{
							componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent.goToSignInWithClearSession(); });
						}
					}
					else
					{
						componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent.goToSignInWithClearSession(); });
					}
				},
				fail(modelResponse: ResponseModel): void
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponseWithRetry(modelResponse, () => { componentCurrent.callVerifyBRIByEmail(); });
				},
				signOut(modelResponse: ResponseModel): void
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent.goToSignInWithClearSession(); });
				}
			}, modelUser);
		}
		else
		{
			this._functionUserInterface.showDialogFromModelResponse(modelResponseValidation, () => { });
		}
	}
	//#endregion


	//region GETTER

	private getStringEmailTokenFromForm(): string
	{
		let stringEmailToken: string = "";

		for (const numberEmailToken of this._arrayNumberEmailToken)
		{
			if (numberEmailToken !== null && numberEmailToken !== undefined)
			{
				stringEmailToken += numberEmailToken.toString();
			}
			else
			{

			}
		}

		return stringEmailToken;
	}

	//endregion

	//#region LISTENER

	clickInputToken(numberIndex: number): void
	{
		if (this._arrayElementInputEmailToken != null)
		{
			this._arrayElementInputEmailToken[numberIndex].focus();
			(this._arrayElementInputEmailToken[numberIndex] as HTMLInputElement).value = "";
		}
		else
		{

		}
	}

	listenInputToken(event: KeyboardEvent, numberIndex: number): void
	{
		if (this._arrayElementInputEmailToken != null)
		{
			if (event.code === "Backspace")
			{
				if (numberIndex > 0)
				{
					this._arrayElementInputEmailToken[numberIndex - 1].focus();
					(this._arrayElementInputEmailToken[numberIndex - 1] as HTMLInputElement).value = "";
				}
				else
				{

				}
			}
			else
			{
				if (numberIndex < this._arrayElementInputEmailToken.length - 1)
				{
					if (event.code === "Tab")
					{
						(this._arrayElementInputEmailToken[numberIndex] as HTMLInputElement).value = "";
					}
					else
					{
						this._arrayElementInputEmailToken[numberIndex + 1].focus();
						(this._arrayElementInputEmailToken[numberIndex + 1] as HTMLInputElement).value = "";
					}
				}
				else
				{
					this.callVerifyBRIByEmail();
				}
			}
		}
		else
		{

		}
	}

	//#endregion
}

//#endregion